export const apiUrl = {
    LOGIN_URL: `https://api.enc-worksphere.com/api/auth/login`,
    MASTER_SUBTEAM_URL:`https://api.enc-worksphere.com/api/project-detail/subteam/`,
    REFRESH_TOKEN_URL: `https://api.enc-worksphere.com/api/auth/refresh_token/`,
    REGISTER_NEW_PROJECT_URL: `https://api.enc-worksphere.com/api/project-detail/register-project/`,
    EDIT_PROJECT_URL: `https://api.enc-worksphere.com/api/project-detail/edit-project/`,
    MEMBER_SEARCH_URL: `https://api.enc-worksphere.com/api/project-member/member-search/`,
    ADD_MEMBER_URL: `https://api.enc-worksphere.com/api/project-member/add-member/`,
    FIND_MANAGER_URL: `https://api.enc-worksphere.com/api/project-member/find-manager/`,
    MEMBERS_URL: `https://api.enc-worksphere.com/api/project-member/members/`,
    DELETE_MEMBER_URL: `https://api.enc-worksphere.com/api/project-member/member/`,
    UPSERT_TARGET_SAVING_URL: `https://api.enc-worksphere.com/api/project-cost-saving/add-target-saving/`,
    UPSERT_TARGET_SAVING_ARRAY_URL: `https://api.enc-worksphere.com/api/project-cost-saving/upsert-target-saving/`,
    UPSERT_ACTUAL_SAVING_URL: `https://api.enc-worksphere.com/api/project-cost-saving/add-actual-saving/`,
    UPSERT_ACTUAL_SAVING_ARRAY_URL: `https://api.enc-worksphere.com/api/project-cost-saving/upsert-actual-saving/`,
    GEN_SAVING_MONTH_YEAR_URL: `https://api.enc-worksphere.com/api/project-cost-saving/gen-saving/`,
    CHANGE_SAVING_TYPE_URL: `https://api.enc-worksphere.com/api/project-cost-saving/change-saving-type/`,
    GET_SAVING_URL: `https://api.enc-worksphere.com/api/project-cost-saving/`,
    GET_SAVING_TYPE_URL: `https://api.enc-worksphere.com/api/project-cost-saving/type/`,
    PROJECT_SUMMARY_URL: `https://api.enc-worksphere.com/api/projects/projects/`,
    ALL_PROJECT_SUMMARY_URL: `https://api.enc-worksphere.com/api/projects/all-projects/`,
    ALL_PROJECTS_SUMMARY_URL: `https://api.enc-worksphere.com/api/projects/all-projects/summary`,
    TEAM_PROJECTS_SUMMARY_URL: `https://api.enc-worksphere.com/api/projects/team-projects/summary`,
    PROJECT_FORM_URL: `https://api.enc-worksphere.com/api/projects/project/`,
    DELETE_PROJECT_URL: `https://api.enc-worksphere.com/api/projects/project/`,
    DELETE_MANAGER_URL: `https://api.enc-worksphere.com/api/project-detail/delete-project-manager/`,
    ADD_PROGRESS_URL: `https://api.enc-worksphere.com/api/project-progress/add-progress/`,
    LATEST_PROGRESS_URL: `https://api.enc-worksphere.com/api/project-progress/get-latest-progress/`,
    ALL_PROGRESS_URL: `https://api.enc-worksphere.com/api/project-progress/get-progress/`,
    DELETE_PROGRESS_URL: `https://api.enc-worksphere.com/api/project-progress/delete-progress/`,
    BU_PROJECT_DONUT_URL: `https://api.enc-worksphere.com/api/report-bu/projects-by-bu/`,
    BU_SAVING_COLUMN_URL: `https://api.enc-worksphere.com/api/report-bu/savings-by-bu/`,
    BU_SUMMARY_TABLE_URL: `https://api.enc-worksphere.com/api/report-bu/summary-bu-savings/`,
    TEAM_PROJECT_DONUT_URL: `https://api.enc-worksphere.com/api/report-team/projects-by-team/`,
    TEAM_SAVING_COLUMN_URL: `https://api.enc-worksphere.com/api/report-team/savings-by-team/`,
    TEAM_SUMMARY_TABLE_URL: `https://api.enc-worksphere.com/api/report-team/summary-team-savings/`,
    PROJECTS_STATUS_URL: `https://api.enc-worksphere.com/api/report-status/status-by-projects/`,
    TEAMS_STATUS_URL: `https://api.enc-worksphere.com/api/report-status/status-by-teams/`,
    PROJECTS_PERCENT_RANGE_STATUS_URL: `https://api.enc-worksphere.com/api/report-status/percent-range-status-by-projects/`,
    TEAMS_PERCENT_RANGE_STATUS_URL: `https://api.enc-worksphere.com/api/report-status/average-progress-status-by-teams/`,
    COST_SAVINGS_URL: `https://api.enc-worksphere.com/api/report-cost-saving/savings-year-month/`,
    TEAM_COST_SAVINGS_URL: `https://api.enc-worksphere.com/api/report-cost-saving/savings-teams/`,

  };
  