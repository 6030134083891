import React from 'react'
import Chart from 'react-apexcharts';
import Box from '@mui/material/Box';

const MultiLineGraph = (props) => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const series = [
    {
      name: 'Estimated',
      data: props.data.running_target.map(value => value === 0 ? null : value),
    },
    {
      name: 'Actual',
      data: props.data.running_actual.slice(0, currentMonth).map(value => value === 0 ? null : value),
    },
  ];

  const maxYValue = Math.max(...series[0].data).toFixed(1);
  const annotationYValue = props.data.ideal_target[0];
  console.log(typeof annotationYValue, ' ', annotationYValue)
  console.log(maxYValue)
  const yAxisMax = maxYValue < annotationYValue ? annotationYValue + 10 : maxYValue;

  // Function to set the annotation text based on y-value
  const getAnnotationText = (yValue) => {
    if (yValue === annotationYValue) {
      return `Target = ${annotationYValue}`; 
    }
    return `Target = ${yValue}`;
  };

  const options = {
    chart: {
      type: 'line',
      height: 350,
      toolbar: {
        show: true,
        theme: 'dark' 
      },
      zoom: {
        enabled: true,
        type: 'xy',  
        autoScaleYaxis: true,  
        zoomedArea: {
          fill: {
            color: '#90CAF9',
            opacity: 0.4
          },
          stroke: {
            color: '#0D47A1',
            opacity: 0.4,
            width: 1
          }
        }
      },
      background: 'transparent',
      foreColor: '#B2EBF2'
    },
    xaxis: {
      categories: props.data.label,
      labels: {
        style: {
          colors: '#B2EBF2',
          fontSize: '14px',
        }
      }
    },
    yaxis: {
      min: 0,
      max: yAxisMax, 
      labels: {
        style: {
          colors: '#B2EBF2', 
          fontSize: '14px',
        },
        formatter: function (value) {
          return value != null ? value.toFixed(1) : ''; 
        }
      },
      title: {
        text: 'Unit:MB', 
        rotate: 0,
        offsetX: 20,
        offsetY: -145,
        style: {
          color: '#B2EBF2', 
          fontSize: '14px',
          fontFamily: 'CPF Imm Sook',
        }
      }
    },
    stroke: {
      width: [3, 3],
      curve: 'straight',
      dashArray: [5, 0]
    },
    colors: ['#FF7F50', '#20B2AA'], 
    tooltip: {
      shared: true,
      intersect: false,
      theme: 'dark',
      style: {
        fontSize: '14px',
        fontFamily: 'CPF Imm Sook',
        color: '#B2EBF2' // Tooltip text color
      }
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      labels: {
        colors: '#B2EBF2', // Legend text color
        fontFamily: 'CPF Imm Sook',
      }
    },
    markers: {
      size: 4,
      shape: 'circle', // Default marker shape
      customHTML: function({ value, seriesIndex, dataPointIndex, w }) {
        // Check if the y-value equals the x-value (dataPointIndex + 1, assuming 1-based index for months)
        if (value === dataPointIndex + 1) {
          return '<div style="width: 10px; height: 10px; background-color: red;"></div>';
        }
        return '';
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (value) {
        return value.toFixed(2); // Format data labels to 2 decimal places
      },
      style: {
        colors: ['#0f0c29'] // Data labels color
      }
    },
    grid: {
      show: true, // you can either change here to disable all grids
      xaxis: {
        lines: {
          show: false  // or just here to disable only x axis grids
         }
       },  
      yaxis: {
        lines: { 
          show: false  // or just here to disable only y axis
         }
       },   
    },
    annotations: {
      yaxis: [{
        y: annotationYValue,
        borderColor: '#FF4560',
        label: {
          borderColor: '#FF4560',
          style: {
            color: '#fff',
            background: '#FF4560',
          },
          text: getAnnotationText(annotationYValue),
        }
      }]
    },
  };    
  return (
    <div>
      <Box sx={{ width: '100%', display: 'block', justifyContent: 'center' }}>
        <Chart options={options} series={series} type="line" height={350} />
      </Box> 
    </div>
  )
}

export default MultiLineGraph
