import { Navigate } from "react-router-dom";
import Login from "./scenes/login";
import AfterLogin from "./scenes/afterLogin";
import Home from './scenes/home'
import FormCreate from './scenes/formCreate'
import FormSummary from "./scenes/formSummary";
import Report from "./scenes/report/index";

const routes = (isLoggedIn) => [
    // Need authen pages
    {
        path: "/",
        element: isLoggedIn ? <AfterLogin /> : <Navigate to="/login" />,
        children: [
        { path: "home", element: <Home/> },
        { path: "/", element: <Navigate to="/home" /> },
        { path: "project-summary", element: <FormSummary /> },
        { path: "form/:project_name", element: <FormCreate /> },
        { path: "form", element: <FormCreate /> },
        { path: "report", element: <Report /> },
        ],
    },
    // No need authen pages
    {
        path: "/",
        element: !isLoggedIn ? <Login /> : <Navigate to="/home" />,
        children: [
        { path: "login", element: <Login /> },
        { path: "/", element: <Navigate to="/home" /> },
        ],
    },
];


export default routes;