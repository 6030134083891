import React from 'react'
import Chart from 'react-apexcharts';
import Box from '@mui/material/Box';
import { fontFamily } from '@mui/system';

const DonutChart = (props) => {
  const options = {
      chart: {
          type: 'donut',
          backgroundColor: 'transparent',
          foreColor: '#B2EBF2' // Default text color
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                show: true,
                showAlways: true,
                label: props.mode === 'total'? 'Total project' : 'Average',
                fontSize: '22px',
                fontFamily: 'CPF Imm Sook',
                fontWeight: 600,
                color: '#B2EBF2',
                formatter: function (w) {
                  // Sum all series to show the total
                  if (props.mode === 'total') {
                    return w.globals.seriesTotals.reduce((a, b) => {
                    return a + b
                  }, 0)
                  }
                  else {
                    return  Number(props.data.avg_progress[0]).toFixed(2) + '%';
                  }
                }
              }
            }
          }
        }
      },
      labels: props.data.label,
      responsive: [{
          breakpoint: 480,
          options: {
              chart: {
                  width: 200
              },
              legend: {
                  position: 'bottom'
              }
          }
      }],
      legend: {
        position: props.legend_position,  // Positioning legend at the bottom
        horizontalAlign: 'left',  // Center aligning the legend
        fontSize: '16px',  // You can adjust the font size for better visibility
        fontFamily: 'CPF Imm Sook',
        formatter: function(seriesName, opts) {
            return [seriesName];
        },
        itemMargin: {
            vertical: 5  // Adding some vertical margin for better spacing between items
        },
        // width: 300,  // Setting a specific width for the legend
        markers: {
            width: 10,
            height: 10
        },
      },
      tooltip: {
        enabled: true,
        theme: 'dark',
        x: {
          show: true,
          format: 'dd MMM', // Date format example, change as needed
        },
        y: {
          formatter: function (val) {
            return `Count: ${val}`; // Custom tooltip value formatting
          }
        },
        marker: {
          show: true
        }
      },
      dataLabels: {
        enabled: true,
        style: {
          colors: ['#ffffff'], // Set the data label text color
          textShadow: 'none',          
        }
      },
      colors: props.colors,
  };

  return (
    <div>
      <Box sx={{ width: '100%', display: 'block', justifyContent: 'center' }}>
        <Chart options={options} series={props.data.series} type="donut" height={props.height} width='100%' />
      </Box> 
    </div>
  )
}

export default DonutChart
