import { useState } from 'react'
import { FaSearch } from "react-icons/fa";
import { apiUrl } from '../constants'
import axios from 'axios'
import "./SearchBar.css";
import "./SearchResultList.css";
import "./SearchResult.css"
import { fontSize } from '@mui/system';
const SearchBar = ({subPath, setMemberListJSON, setProjectManager, tabValue}) => {
    console.log('subPath: ', subPath)
    const [text, setText] = useState("");
    const [searchResults, setSearchResults] = useState([]);

    const fetchMember = async (member) => {
      const memberSearch = await axios.post(`${apiUrl.MEMBER_SEARCH_URL}`,{
        'textSearch': text
      },{
      headers: {
          Authorization: `Bearer ${localStorage.getItem("rb_ac_token_pms")}`,                
          }
      }).then(response => {
          if (response.status === 200) {
            setSearchResults(response.data.name)
          }
      })
      .catch(error => {
          console.log('error: ', error)
          
      })
    }
    const handleTextChange = (value) => {
        setText(value);
        fetchMember(value);
      };

    const handleAddMember = async (value) => {
      if (tabValue === '1') {
        setManager(value)
      } else {
        addMember(value)
      }
      
    }

    const memberList = async () => {
      const memberList = await axios.post(`${apiUrl.MEMBERS_URL}`, {
        'checkOn': subPath,
      }, {
          headers: {
              Authorization: `Bearer ${localStorage.getItem("rb_ac_token_pms")}`,                
              }
          }
        )
        .then(response => {
            if (response.status === 200) {
                setMemberListJSON(response.data.members)                

            }
        })
        .catch(error => {
            // console.log('error: ', error)
            
        })

    }

    const addMember = async (value) => {
      console.log('add member')
      const [firstName, lastName] = value.split(' ')
      const memberSearch = await axios.post(`${apiUrl.ADD_MEMBER_URL}`,{
      'checkOn': subPath,
      'firstName': firstName,
      'lastName':lastName, 
      'role': tabValue === 1? 'Project Manager': 'Member'
      },{
      headers: {
          Authorization: `Bearer ${localStorage.getItem("rb_ac_token_pms")}`,                
          }
      }).then(response => {
          if (response.status === 200) {
            setSearchResults(response.data.name)
          }
      })
      .catch(error => {
          console.log('error: ', error)
          
      }).then(() => {
        memberList()
      })
    }

    const setManager = (value) => {
      setProjectManager(value)
    }

  return (
    <div>
      <div className="input-wrapper">
        <FaSearch id="search-icon" />
        <input
          placeholder="Type to search..."
          value={text}
          onChange={(e) => handleTextChange(e.target.value)}
        />
      </div>
      <div className="results-list">
      {searchResults !== undefined ?searchResults.map((result, id) => {
        return (
          <div
            className="search-result"
            onClick={(e) => handleAddMember(result)}
          >
          {result}
    </div>
        )
      }): ''}
    </div>
    </div>
    
  )
}

export default SearchBar
