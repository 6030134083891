import React from 'react'
import Chart from 'react-apexcharts';
import Box from '@mui/material/Box';
import { color } from '@mui/system';

const BarChart = (props) => {
  const options = {
    chart: {
      type: 'bar',
      height: 200,
      toolbar: {
        show: false, // Disable the download and other toolbar options
      },
      backgroundColor: 'transparent',
      foreColor: '#B2EBF2' // Default text color
    },
    xaxis: {
      categories: props.data.label,
      labels: {
        style: {
          colors: '#B2EBF2', // X-axis labels color
          fontSize: '14px',
        }
      }
    },
    yaxis: {
      labels: {
        style: {
          colors: '#B2EBF2', // Y-axis labels color
          fontSize: '14px',
        }
      }
    },
    plotOptions: {
      bar: {
        horizontal: true, // Make the bar chart horizontal
        dataLabels: {
          position: 'top', // Position the data labels on top of the bars
        },
      },
    },
    dataLabels: {
      enabled: false,
      formatter: function (val, opts) {
        return val;
      },
      offsetX: 20,
      style: {
        fontSize: '14px',
        colors: ['#B2EBF2'] // Data labels color
      },
    },
    tooltip: {
      theme: 'dark',
      style: {
        fontSize: '14px',
        fontFamily: 'CPF Imm Sook',
        color: '#B2EBF2' // Tooltip text color
      }
    },
    legend: {
      labels: {
        colors: '#B2EBF2', // Legend text color
      }
    },
    grid: {
      show: false, // Disable grid lines
    },
  };
  
  const series = [
    {
      name: 'Actual',
      data: props.data.actual,
    },
  ];
  return (
    <div>
      <Box sx={{ width: '100%', display: 'block', justifyContent: 'center' }}>
        <Chart options={options} series={series} type="bar" height={200} />
      </Box> 
    </div>
  )
}

export default BarChart
