import { Box, Button, Checkbox, Tab, TextField, Grid, Paper, styled, Typography, InputLabel } from '@mui/material'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { useState, useEffect} from 'react'
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { tokens } from "../../theme";
import '../../index.css'
import Swal from 'sweetalert2';
import { apiUrl } from '../../constants'
import axios from 'axios'
import SearchBar from '../../components/SearchBar'
import withReactContent from 'sweetalert2-react-content';
import { useLocation } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import Slider from '@mui/material/Slider';
import CircularProgress from '@mui/material/CircularProgress';
//import { SearchResult } from '../../components/SearchResult'
// import { SearchResultsList } from "./components/SearchResultsList";

const MySwal = withReactContent(Swal);

const ProSpan = styled('span')({
    display: 'inline-block',
    height: '1em',
    width: '1em',
    verticalAlign: 'middle',
    marginLeft: '0.3em',
    marginBottom: '0.08em',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundImage: 'url(https://mui.com/static/x/pro.svg)',
    backgroudColor: '#1C1B29'
  });


function Label({ componentName, valueType, isProOnly }) {
    const content = (
      <span>
        <strong>{componentName}</strong> for {valueType} editing
      </span>
    );
  
    if (isProOnly) {
      return (
        <Stack direction="row" spacing={0.5} component="span">
          <Tooltip title="Included on Pro package">
            <a
              href="https://mui.com/x/introduction/licensing/#pro-plan"
              aria-label="Included on Pro package"
            >
              <ProSpan />
            </a>
          </Tooltip>
          {content}
        </Stack>
      );
    }
  
    return content;
  }


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: 'transparent',
    ...theme.typography.body2,
    textAlign: 'center',
    color: '#1C1B29',
    marginLeft: '2%',
    marginRight: '2%',
    marginBottom: '2%',
    maxHeight: '90vh', 
    overflowY: 'auto',

    [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginLeft: 0,
        marginRight: 0,
        marginBottom: '10px',
        height: 'auto',
    },
}));

const FormCreateEdit = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const path = useLocation();
    const currentPath = path.pathname;
    const [mode, setMode] = useState();
    const [subPath, setSubPath] = useState(currentPath.split("/form/")[1]);
    if (subPath) {
        localStorage.setItem('rb_uuid_pms', subPath);
    } else {
        localStorage.setItem('rb_uuid_pms', '');
        
    }
    const [isFirstTimeLoading, setIsFirstTimeLoading] = useState(true);
    const [value, setValue] = useState("1")
    const [subTeam, setSubTeam] = useState('')
    const [subTeamSelectionArray, setSubTeamSelectionArray] = useState([])
    const [directionCPF, setDirectionCPF] = useState('')
    const [directionCOE, setDirectionCOE] = useState('')
    const [projectType, setProjectType] = useState([])
    const [topicKPI, setTopicKPI] = useState([])
    const [BU, setBU] = useState('')
    const [year, setYear] = useState('')
    const [projectName, setProjectName] = useState('')
    const [location, setLocation] = useState('')
    const [projectManager, setProjectManager] = useState('')
    const [category, setCategory] = useState('')
    const [selectedStartDate, setSelectedStartDate] = useState(null)
    const [selectedEndDate, setSelectedEndDate] = useState(null)
    const [memberList, setMemberList] = useState([])
    const [memberListJSON, setMemberListJSON] = useState([])
    const [costSavingType, setCostSavingType] = useState('')
    const [startSaving, setStartSaving] = useState(null)
    const [savingMonthYearList, setSavingMonthYearList] = useState([])
    const [costSavingArrayList, setCostSavingArrayList] = useState([])
    const [status, setStatus] = useState('')
    const [percentProgress, setPercentProgress] = useState('')
    const [progressUpdate, setProgressUpdate] = useState('')
    const [nextStep, setNextStep] = useState('')
    const [problem, setProblem] = useState('')
    const [latestProgress, setLatestProgress] = useState([])
    const [allProgress, setAllProgress] = useState([])
    const [expanded, setExpanded] = useState(false);
    const [projectBackground, setProjectBackground] = useState([]);
    const [projectDetail, setProjectDetail] = useState([]);
    const [budget, setBudget] = useState([]);
    const [targetSavingArray, setTargetSavingArray] = useState([]);
    const [actualSavingArray, setActualSavingArray] = useState([]);

    const isMediumScreen = useMediaQuery(theme.breakpoints.down('md')); // Check if screen width is <= 600px (sm breakpoint)
    
    const directionCPFList = [
        'พัฒนาคู่ค้านอกเครือ',
        'ยกระดับคุณภาพ เพื่อเพิ่มประสิทธิภาพการทำงาน',
        'ลดต้นทุนตลอด Value Chain ',
        'มุ่งสู่องค์กร Digital ',
        'การพัฒนาคนให้เป็นคนดีและคนเก่ง',
        'บริษัทที่เป็นสมาชิกที่ดีของสังคม'

    ]
    const directionCOEList = [
        'Automation & IoT พัฒนาขบวนการผลิต,ระบบควบคุม',
        'Level Up technology ยกระดับการซ่อมบำรุง และพัฒนาทักษะของพนักงานทุกระดับด้วยเทคโนโลยี',
        'Best in class LTA ลดความเสี่ยงในการปฏิบัติงาน และ อุบัติเหตุในการทำงาน',
        'Commercialize Innovation สร้างนวัตกรรมเทคโนโลยีวิศวกรรม และ มีมูลค่าผลลัพธ์ทางธุรกิจ ',
        'Technical partnership สร้างพันธมิตร เพิ่มศักยภาพทางธุรกิจ ',
        'Sustainability ดำเนินธุรกิจอย่างยั่งยืน'
    ]
    const projectTypeList = [
        'MOU',
        'Strategy',
        'Innovation',
        'อื่น'
        
        
    ]
    const bUList = [
        'ธุรกิจเกษตร',
        'ธุรกิจในต่างประเทศ',
        'ธุรกิจไก่เนื้อและเป็ดเนื้อ',
        'ธุรกิจไก่ไข่',
        'ธุรกิจสัตว์น้ำ',
        'ธุรกิจสุกร',
        'ธุรกิจอาหารแปรรูปและขนมปัง',
        'ธุรกิจอาหารสัตว์บก',
        'ธุรกิจอาหารสำเร็จรูป',
        'Cross BU',
        'อื่นๆ'
    ]
    const yearList = [
        '2023',
        '2024',
        '2025'
    ]
    const categoryList = [
        'Tangible',
        'Intangible'
    ]
    const costSavingTypeList = [
        'Hard Saving',
        'Soft saving', 
        'Cost Aviodance',
    ]
    const statusList = [
        'Completed',
        'On time & Effective',
        'Delay',
        'On time but not Effective',
        'On hold',
        'Not start'
    ]

    // get all key at first time loading this page
    useEffect(() => {
        if (isFirstTimeLoading === true) {
        checkToken()
        var get_subteam = `${apiUrl.MASTER_SUBTEAM_URL}`
        let data = {'team': localStorage.getItem('rb_team_pms')}
        axios.post(get_subteam, data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("rb_ac_token_pms")}`,                
                }
            }
        )
        .then(response => {
            if (response.status === 200) {
                setSubTeamSelectionArray(response.data.subteams)
                setIsFirstTimeLoading(false)
                
    
            }
        })
        .catch(error => {
            // console.log('error: ', error)
            
        })
        }
    }, []);
    
    // get members
    useEffect(() => {
        checkToken()
        axios.post(`${apiUrl.MEMBERS_URL}`, {
            'checkOn': subPath,
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("rb_ac_token_pms")}`,                
                }
            }
        )
        .then(response => {
            if (response.status === 200) {
                setMemberListJSON(response.data.members)                
    
            }
        })
        .catch(error => {
            // console.log('error: ', error)
            
        })
    }, []);

    // if project name !== '' get all form
    useEffect(() => {
        if (subPath !== undefined) {
            getFilledForm()
            getLatestProgressObj()
            getAllProgressObj()
            getSavingList()
            getSavingType()
        }
    },[])

    useEffect(() => {
        if (subPath !== undefined) {
            getSavingList()
            getSavingType()
        }
    }, [startSaving])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    
    const handleSubTeam = (event) => {
        setSubTeam(event.target.value);
    };
    
    const handleDirectionCPF = (event) => {
        setDirectionCPF(event.target.value);
    };

    const handleDirectionCOE = (event) => {
        setDirectionCOE(event.target.value);
    };

    const handleProjectType = (event) => {
        const selectedValues = event.target.value;
        console.log(event.target.value)
        setProjectType(selectedValues);
    };
    
    const handleBU = (event) => {
        setBU(event.target.value);
    };

    const handleYear = (event) => {
        setYear(event.target.value);
    };
    
    const handleProjectName = (event) => {
        setProjectName(event.target.value);
        localStorage.setItem('rb_project_name_pms', event.target.value);
    };
    
    const handleTopicKPI = (event) => {
        setTopicKPI(event.target.value);

    };
    
    const handleLocation= (event) => {
        setLocation(event.target.value);
    };
    
    const handleProjectManager = (event) => {
        setProjectManager(event.target.value);
        localStorage.setItem('rb_project_manager_pms', event.target.value);
    };
    
    const handleCategory = (event) => {
        setCategory(event.target.value);
    };
    
    const handleStartDateChange = (date) => {
        setSelectedStartDate(date);
    };
    
    const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
    };

    const handleSubmitProjectDetail =  async () => {
        console.log('directoncpf: ' + directionCPF)
        console.log('directoncoe: ' + directionCOE)
        console.log('project type: ' + projectType)
        console.log('bu: ' + BU)
        console.log('year: ' + year)
        console.log('name: ' + projectName)
        console.log('location: '+ location)
        console.log('projectManager: ' + projectManager)
        console.log('category: ' + category)
        console.log('project bg: ' + projectBackground)
        console.log('project dt: ' + projectDetail)
        console.log('budget: ' + budget)
        console.log(selectedStartDate)
        console.log(selectedEndDate)
        console.log(topicKPI)
        if ( directionCPF !== '' && directionCOE !== '' && projectType.length !== 0 && BU !== ''
            && year !== '' && projectName !== '' && location !== '' && projectManager !== '' && category !== ''
            && projectBackground !== '' && projectDetail  !== '' && budget !== '' && topicKPI !== ''
            && selectedStartDate !== null && selectedEndDate !== null) {
            if (localStorage.getItem('rb_uuid_pms') === '') {
                console.log('add project')
                addProject()
            } else {
                console.log('editproject')
                editProject()
            }
            }
            else {
                let title = 'Error'
                let text = 'Please fill out all fields'
                return (
                    Swal.fire({
                        title,
                        text,
                        icon: 'error',
                        confirmButtonText: 'OK',
                      })
                ) 

            }
    };
    
    const handleMemberList = (event) => {
        let title = 'Error'
        let text = 'You can select member of project up to 10 members'
        // console.log(memberList) 
        if (memberListJSON.length === 10 && memberList.filter((member) => member !== event.target.value)) {
            return (
                Swal.fire({
                    title,
                    text,
                    icon: 'error',
                    confirmButtonText: 'OK',
                  })
            ) 
        } else{
            const selectedValues = event.target.value;
            setMemberList(selectedValues);
            const newMember = {
            name: selectedValues.slice(-1)[0],
            project: 'project name',   // Default project name
            responsibility: '',        // Default responsibility
            };
            setMemberListJSON((prevMemberListJSON) => [...prevMemberListJSON, newMember]);
        }

       
    };

    const handleCostSavingType = (event) => {
        setCostSavingType(event.target.value);
        checkToken()
        axios.post(`${apiUrl.CHANGE_SAVING_TYPE_URL}`, {
            'checkOn': subPath,
            'costSavingType': event.target.value
        },{
            headers: {
                Authorization: `Bearer ${localStorage.getItem("rb_ac_token_pms")}`,                
                }
        }).then(response => {
            if (response.status === 200) {
                              
    
            }
        })
        .catch(error => {
            console.log('error: ', error)
            
        })

    };

    const handleProjectBackground= (event) => {
        setProjectBackground(event.target.value);
    };

    const handleProjectDetail= (event) => {
        setProjectDetail(event.target.value);
    };

    const handleBudget= (event) => {
        setBudget(event.target.value);
    };

    // check token
    const checkToken =  async() => {
        let access_exp = localStorage.getItem("rb_ac_exp_pms");
        let refresh_exp = localStorage.getItem("rb_rf_exp_pms");
        if (refresh_exp < Date.now() / 1000) {
            console.log("refresh is expiried");
            // alert('Please log in again')
            localStorage.clear();
            window.location = "/login";
        } else if (access_exp < Date.now() / 1000) {
            const refresh = localStorage.getItem("rb_rf_token_pms");
            
            let data = {
                refresh_token: refresh,
            };
            let result = await axios.post(`${apiUrl.REFRESH_TOKEN_URL}`, data).
            then(response => {
                // console.log("got new access_token!");
                localStorage.setItem("rb_ac_token_pms", response.data.accessToken);
            })
        
        }
    };
    
    // delete member
    const handleDeleteMember = async (projectName, firstName, lastName) => {
        try {
            const deleteResponse = await axios.delete(`${apiUrl.DELETE_MEMBER_URL}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("rb_ac_token_pms")}`,
                },
                data: {
                    'checkOn': subPath,
                    'firstName': firstName,
                    'lastName': lastName
                }
            });
    
            if (deleteResponse.status === 200) {
                await checkToken();
                const memberResponse = await axios.post(`${apiUrl.MEMBERS_URL}`, {
                    'checkOn': subPath,
                }, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("rb_ac_token_pms")}`,
                    }
                });
    
                if (memberResponse.status === 200) {
                    const members = memberResponse.data.members;
                    if (members.length > 0) {
                        setMemberListJSON(members);
                    } else {
                        setMemberListJSON([]);
                    }
                } else {
                    setMemberListJSON([]);
                }
            } else {
                setMemberListJSON([]);
            }
        } catch (error) {
            console.error('Error:', error);
            setMemberListJSON([]);
        }
        
    };

    const handleStartSaving = async (event) => {
        setStartSaving(event)
        setSavingMonthYearList(generateYearMonthList(event))

    }
    
    const handleTargetSavingMonthYear = async (month, target) => {
        checkToken()
        const data = {
            'checkOn': subPath,
            'monthYear': month,
            'targetSaving': target !== ''? target: 0
        }
        // console.log(data)
        axios.post(`${apiUrl.UPSERT_TARGET_SAVING_URL}`, data,{
            headers: {
                Authorization: `Bearer ${localStorage.getItem("rb_ac_token_pms")}`,                
                }
        }).then(response => {
            if (response.status === 200) {
                              
    
            }
        })
        .catch(error => {
            console.log('error: ', error)
            
        })
    }

    const handleTargetSavingMonthYearArray = (month, target) => {
        console.log('handleTargetSavingMonthYearArray');
        setTargetSavingArray(prevArray => {
            const index = prevArray.findIndex(item => item.monthYear === month);

            if (index !== -1) {
                const updatedArray = [...prevArray];
                updatedArray[index] = {
                    ...updatedArray[index],
                    'targetSaving': target !== '' ? target : 0
                };
                return updatedArray;
            } else {
                return [
                    ...prevArray,
                    {
                        'checkOn': subPath,
                        'monthYear': month,
                        'targetSaving': target !== '' ? target : 0
                    }
                ];
            }
        });
    
    }

    const handleGenMonthYear = async (array) => {
        checkToken()
        const data = {
            'array': array,
            'checkOn': subPath 
        }
        axios.post(`${apiUrl.GEN_SAVING_MONTH_YEAR_URL}`, data,{
            headers: {
                Authorization: `Bearer ${localStorage.getItem("rb_ac_token_pms")}`,                
                }
        }).then(response => {
            if (response.status === 200) {
                              
    
            }
        })
        .catch(error => {
            console.log('error: ', error)
            
        })
    }

    const handleSavingMonthYear = async (month, saving) => {
        checkToken()
        const data = {
            'checkOn': subPath,
            'monthYear': month,
            'actualSaving': saving !== ''? saving: 0
        }
        axios.post(`${apiUrl.UPSERT_ACTUAL_SAVING_URL}`, data,{
            headers: {
                Authorization: `Bearer ${localStorage.getItem("rb_ac_token_pms")}`,                
                }
        }).then(response => {
            if (response.status === 200) {
                              
    
            }
        })
        .catch(error => {
            console.log('error: ', error)
            
        })
    }

    const handleActualSavingMonthYearArray = (month, saving) => {
        console.log('handleTargetSavingMonthYearArray');
        setActualSavingArray(prevArray => {
            const index = prevArray.findIndex(item => item.monthYear === month);

            if (index !== -1) {
                const updatedArray = [...prevArray];
                updatedArray[index] = {
                    ...updatedArray[index],
                    'actualSaving': saving !== '' ? saving : 0
                };
                return updatedArray;
            } else {
                return [
                    ...prevArray,
                    {
                        'checkOn': subPath,
                        'monthYear': month,
                        'actualSaving': saving !== '' ? saving : 0
                    }
                ];
            }
        });
    
    }

    const handleSubmitTargetAndCostSavingArrays = async () => {
        console.log('submit cost saving')
        handleSubmitTargetSavingArrays();
        handleSubmitActualSavingArrays();
        getSavingList();
    }

    const handleSubmitTargetSavingArrays = async () => {
        checkToken()
        axios.post(`${apiUrl.UPSERT_TARGET_SAVING_ARRAY_URL}`, targetSavingArray,{
            headers: {
                Authorization: `Bearer ${localStorage.getItem("rb_ac_token_pms")}`,                
            }
        }).then(response => {
            if (response.status === 200) {
    
            }
        })
        .catch(error => {
            console.log('error: ', error)
            
        })

    }

    const handleSubmitActualSavingArrays = async () => {
        checkToken()
        axios.post(`${apiUrl.UPSERT_ACTUAL_SAVING_ARRAY_URL}`, actualSavingArray,{
            headers: {
                Authorization: `Bearer ${localStorage.getItem("rb_ac_token_pms")}`,                
            }
        }).then(response => {
            if (response.status === 200) {
    
            }
        })
        .catch(error => {
            console.log('error: ', error)
            
        })
    }

    const handleSetStatus = (event) => {
        setStatus(event.target.value)
    }

    const handleSetPercentProgress = (event) => {
        setPercentProgress(event.target.value)
    }

    const handleSetProgressUpdate = event => {
        setProgressUpdate(event.target.value)
    }

    const handleSetNextStep = (event) => {
        setNextStep(event.target.value)
    }

    const handleSetProblem = (event) => {
        setProblem(event.target.value)
    }

    const handleSubmitProjectProgress =  async () => {
        const addProjectProgress = await axios.post(`${apiUrl.ADD_PROGRESS_URL}`,{
            'checkOn': subPath,
            'status': status,
            'percentProgress': percentProgress,
            'progressUpdate': progressUpdate,
            'nextStep': nextStep,
            'problem': problem
    },{
        headers: {
            Authorization: `Bearer ${localStorage.getItem("rb_ac_token_pms")}`,                
            }
        }).then(response => {
            if (response.status === 200) {
                setPercentProgress(response.data.progress.percent_progress)
                setLatestProgress([response.data.progress])
                setExpanded(false)
                getAllProgressObj()
            }
        })
        .catch(error => {
            console.log('error: ', error)
            let title = 'Error'
                let text = 'Cannot add a progress.'
                return (
                    Swal.fire({
                        title,
                        text,
                        icon: 'error',
                        confirmButtonText: 'OK',
                      })
                ) 
        }).then(response => {
            if (response.status === 200) {
                let title = 'Info'
                let text = 'Add progress.'
                return (
                    Swal.fire({
                        title,
                        text,
                        icon: 'info',
                        confirmButtonText: 'OK',
                      })
                )
            }
        })
        .catch(error => {
            console.log('error: ', error)
            
        }) 
    };

    const handleDeleteManager = async (event) => {
        await axios.post(`${apiUrl.DELETE_MANAGER_URL}`, {
            'checkOn': subPath,
            'name': projectManager
          }, {
              headers: {
                  Authorization: `Bearer ${localStorage.getItem("rb_ac_token_pms")}`,                
                  }
              }
          )
          .then(response => {
              if (response.status === 200) {
                setProjectManager('')
              }
          })
          .catch(error => {
              // console.log('error: ', error)
              
          })
        
    }

    const getFilledForm = async () => {
        await axios.post(`${apiUrl.PROJECT_FORM_URL}`, {
            'checkOn': subPath
          }, {
              headers: {
                  Authorization: `Bearer ${localStorage.getItem("rb_ac_token_pms")}`,                
                  }
              }
          )
          .then(response => {
              if (response.status === 200) {
                if (response.data.project[0].subteam){
                    setSubTeam(response.data.project[0].subteam)
                }
                if (response.data.project[0].strategic_direction_cpf) {
                    setDirectionCPF(response.data.project[0].strategic_direction_cpf)
                }
                if (response.data.project[0].strategic_direction_coe) {
                    setDirectionCOE(response.data.project[0].strategic_direction_coe)
                }
                if (response.data.project[0].bu){
                    setBU(response.data.project[0].bu)
                }
                if (response.data.project[0].year) {
                    setYear(response.data.project[0].year)
                }
                if (response.data.project[0].location) {
                    setLocation(response.data.project[0].location)
                }
                if (response.data.project[0].project_type) {
                    setProjectType(response.data.project[0].project_type.slice(1, -1).split(',').map(s => s.trim().replace(/['"]+/g, '').replace(/\\+/g, '')))
                }
                if (response.data.project[0].project_manager) {
                    setProjectManager(response.data.project[0].project_manager)
                }
                if (response.data.project[0].category) {
                    setCategory(response.data.project[0].category)
                }
                if (response.data.project[0].project_name) {
                    setProjectName(response.data.project[0].project_name)
                }
                if (response.data.project[0].start_date) {
                    setSelectedStartDate(response.data.project[0].start_date)
                }
                if (response.data.project[0].finish_date) {
                    setSelectedEndDate(response.data.project[0].finish_date) 
                }
                if (response.data.project[0].project_background) {
                    setProjectBackground(response.data.project[0].project_background) 
                }
                if (response.data.project[0].project_detail) {
                    setProjectDetail(response.data.project[0].project_detail) 
                }
                if (response.data.project[0].topic_kpi) {
                    setTopicKPI(response.data.project[0].topic_kpi) 
                }
                if (response.data.project[0].budget_mb) {
                    setBudget(response.data.project[0].budget_mb) 
                }
              }
          })
          .catch(error => {
              // console.log('error: ', error)
              
          })
    }

    const getSavingList = async () => {
        await axios.post(`${apiUrl.GET_SAVING_URL}`, {
            'checkOn': subPath
          }, {
              headers: {
                  Authorization: `Bearer ${localStorage.getItem("rb_ac_token_pms")}`,                
                  }
              }
          )
          .then(response => {
              if (response.status === 200) {
                setCostSavingArrayList(response.data.savings)
                setStartSaving(response.data.savings[0].month_year)
              }
          })
          .catch(error => {
              // console.log('error: ', error)
              
          })
    }

    const getSavingType = async () => {
        await axios.post(`${apiUrl.GET_SAVING_TYPE_URL}`, {
            'checkOn': subPath
          }, {
              headers: {
                  Authorization: `Bearer ${localStorage.getItem("rb_ac_token_pms")}`,                
                  }
              }
          )
          .then(response => {
              if (response.status === 200) {
                console.log('saving type: ', response.data.savings[0].cost_saving_type)
                setCostSavingType(response.data.savings[0].cost_saving_type)
              }
          })
          .catch(error => {
              // console.log('error: ', error)
              
          })
    }

    const editProject = async () => {
        const editProjectDetail = await axios.post(`${apiUrl.EDIT_PROJECT_URL}`,{
            'team': localStorage.getItem('rb_team_pms'),
            'subteam': subTeam,
            'strategicDirectionCpf': directionCPF,
            'strategicDirectionCoe': directionCOE,
            'projectType': projectType,
            'bu': BU,
            'location': location,
            'projectName': projectName,
            'projectManager': projectManager,
            'year': year,
            'category': category,
            'topicKPI': topicKPI,
            'startDate': selectedStartDate,
            'endDate': selectedEndDate,
            'checkOn': subPath,
            'projectBackground': projectBackground,
            'projectDetail': projectDetail,
            'budget': budget
        },{
            headers: {
                Authorization: `Bearer ${localStorage.getItem("rb_ac_token_pms")}`,                
                }
            }).then(response => {
                if (response.status === 200) {
                    setSubTeam(response.data.project.subteam)
                    setDirectionCPF(response.data.project.strategic_direction_cpf)
                    setDirectionCOE(response.data.project.strategic_direction_coe)
                    setBU(response.data.project.bu)
                    setYear(response.data.project.year)
                    setLocation(response.data.project.location)
                    setProjectType([response.data.project.project_type.replace(/\\+/g, '').slice(2, -2)])
                    setProjectManager(response.data.project.project_manager)
                    setCategory(response.data.project.category)
                    setProjectName(response.data.project.project_name)
                    setSelectedStartDate(response.data.project.start_date)
                    setSelectedEndDate(response.data.project.finish_date)
                    setProjectBackground(response.data.project.project_background)
                    setProjectDetail(response.data.project.project_detail)
                    setTopicKPI(response.data.project.topic_kpi)
                    setBudget(response.data.project.budget_mb)
                }
            })
            .catch(error => {
                console.log('error: ', error)
                let title = 'Error'
                let text = 'Cannot edit project. \nPlease fill out all fields'
                return (
                    Swal.fire({
                        title,
                        text,
                        icon: 'error',
                        confirmButtonText: 'OK',
                      })
                ) 
                
            }).then(() => {
                axios.post(`${apiUrl.ADD_MEMBER_URL}`,{
                'checkOn': localStorage.getItem('rb_uuid_pms'),
                'firstName': projectManager.split(' ')[0],
                'lastName':projectManager.split(' ')[1], 
                'role': 'Project Manager'
                },{
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("rb_ac_token_pms")}`,                
                    }
                }).then(response => {
                    if (response.status === 200) {
                        setValue('2') 
                        let title = 'Info'
                        let text = 'Edited project detail.'
                        return (
                            Swal.fire({
                                title,
                                text,
                                icon: 'info',
                                confirmButtonText: 'OK',
                            })
                        )
                    }
                })
                .catch(error => {
                    console.log('error add manager----: ', error)
                    setValue('2') 
                        let title = 'Info'
                        let text = 'Edited project detail.'
                        return (
                            Swal.fire({
                                title,
                                text,
                                icon: 'info',
                                confirmButtonText: 'OK',
                            })
                        )
                    
                })
                })
    }

    const addProject = async () => {
        const registerProjectDetail = await axios.post(`${apiUrl.REGISTER_NEW_PROJECT_URL}`,{
            'team': localStorage.getItem('rb_team_pms'),
            'subteam': subTeam,
            'strategicDirectionCpf': directionCPF,
            'strategicDirectionCoe': directionCOE,
            'projectType': projectType,
            'bu': BU,
            'location': location,
            'projectName': projectName,
            'projectManager': projectManager,
            'year': year,
            'category': category,
            'topicKPI': topicKPI,
            'startDate': selectedEndDate,
            'endDate': selectedEndDate,
            'projectBackground': projectBackground,
            'projectDetail': projectDetail,
            'budget': budget
    },{
        headers: {
            Authorization: `Bearer ${localStorage.getItem("rb_ac_token_pms")}`,                
            }
        }).then(response => {
            if (response.status === 200) {
                setSubPath(response.data.project.check_on)
                localStorage.setItem('rb_uuid_pms', response.data.project.check_on)
                setSubTeam(response.data.project.subteam)
                setDirectionCPF(response.data.project.strategic_direction_cpf)
                setDirectionCOE(response.data.project.strategic_direction_coe)
                setBU(response.data.project.bu)
                setYear(response.data.project.year)
                setLocation(response.data.project.location)
                setProjectType([response.data.project.project_type.replace(/\\+/g, '').slice(2, -2)])
                setProjectManager(response.data.project.project_manager)
                setCategory(response.data.project.category)
                setProjectName(response.data.project.project_name)
                setSelectedStartDate(response.data.project.start_date)
                setSelectedEndDate(response.data.project.finish_date)
                setTopicKPI(response.data.project.topic_kpi)
            }
        })
        .catch(error => {
            console.log('error: ', error)
            let title = 'Error'
                let text = 'Cannot add new project. \nPlease fill out all fields'
                return (
                    Swal.fire({
                        title,
                        text,
                        icon: 'error',
                        confirmButtonText: 'OK',
                      })
                ) 
            
        }).then(() => {
        axios.post(`${apiUrl.ADD_MEMBER_URL}`,{
        'checkOn': localStorage.getItem('rb_uuid_pms'),
        'firstName': projectManager.split(' ')[0],
        'lastName':projectManager.split(' ')[1], 
        'role': 'Project Manager'
        },{
        headers: {
            Authorization: `Bearer ${localStorage.getItem("rb_ac_token_pms")}`,                
            }
        }).then(response => {
            if (response.status === 200) {
                setValue('2')
                let title = 'Info'
                let text = 'Registered new project.'
                return (
                    Swal.fire({
                        title,
                        text,
                        icon: 'info',
                        confirmButtonText: 'OK',
                      })
                )
            }
        })
        .catch(error => {
            console.log('error: ', error)
            
        })
        })
    }

    const getLatestProgressObj = async () => {
        axios.post(`${apiUrl.LATEST_PROGRESS_URL}`,{
            'checkOn': localStorage.getItem('rb_uuid_pms'),
            },{
            headers: {
                Authorization: `Bearer ${localStorage.getItem("rb_ac_token_pms")}`,                
                }
            }).then(response => {
                if (response.status === 200) {
                    setLatestProgress(response.data.progress)
                }
            })
            .catch(error => {
                console.log('error: ', error)
                
            })
    }

    const getAllProgressObj = async () => {
        axios.post(`${apiUrl.ALL_PROGRESS_URL}`,{
            'checkOn': localStorage.getItem('rb_uuid_pms'),
            },{
            headers: {
                Authorization: `Bearer ${localStorage.getItem("rb_ac_token_pms")}`,                
                }
            }).then(response => {
                if (response.status === 200) {
                    setAllProgress(response.data.progress)
                }
                if (response.status === 404) {
                    setAllProgress()
                }
            })
            .catch(error => {
                console.log('error: ', error)
                if (error.message === 'Request failed with status code 404') {
                    setAllProgress()
                }
                
            })
    }

    const handleDeleteProgress = async (time) => {
        await axios.delete(`${apiUrl.DELETE_PROGRESS_URL}`,{
        headers: {
            Authorization: `Bearer ${localStorage.getItem("rb_ac_token_pms")}`,                
        },
            data: {'checkOn': subPath, 'time': time
        }
        }).then(response => {
            if (response.status === 200) {
                checkToken()
                getAllProgressObj()
            }
        })
        .catch(error => {
            console.log('error: ', error)
            
        })
        
    };

    const handleToggle = () => {
        setExpanded(!expanded);
    };

    function generateYearMonthList(date) {
        const monthlyData = [];
    
        // Get the starting month and year from the provided date
        let currentDate = new Date(date);
        const startMonth = currentDate.getMonth();
        const startYear = currentDate.getFullYear();
    
        for (let i = 0; i < 12; i++) {
            const month = (startMonth + i) % 12;
            const year = startYear + Math.floor((startMonth + i) / 12);
    
            const formattedMonth = `${year}-${(month + 1).toString().padStart(2, '0')}`;
            monthlyData.push({ month: formattedMonth, target:0, saving: 0 });
        }
        handleGenMonthYear(monthlyData)
        return monthlyData;
    }

  return (
    <Box  sx={{ padding: '0px', backgroundColor: 'transparent',}}>
        <Typography
            variant="h5"
            fontWeight="600"
            color='#FFD700'
            marginLeft='2%'
        >
            {projectName}
        </Typography>
        <Item>
            <Box sx={{ width: '100%', height: '90vh', typography: 'body1', backgroundColor: 'transparent', }}>
                
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                        sx={{
                            '& .MuiTab-root': {
                            color: '#D3D3D3', // Light gray text color
                            backgroundColor: '#008282', // Teal background color
                            '&.Mui-selected': {
                                backgroundColor: '#006868', // Darker teal background color for selected tab
                                color: '#FFD700', // Light yellow text color for selected tab
                            },
                            },
                            '& .MuiTabs-indicator': {
                            backgroundColor: '#FFD700', // Gold-like color for the indicator (can be adjusted to match the teal palette)
                            },
                        }}
                        >
                        <Tab label="Project Detail" value="1" />
                        <Tab label="Member" value="2" />
                        <Tab label="Cost Saving" value="3" />
                        <Tab label="Progress" value="4" />
                    </TabList>
                    </Box>
                    {/* TAB 1 */}
                    <TabPanel value="1">
                        {/* ROW 1 */}
                        <Box 
                        // mt="5px"
                        // ml="1.3%"
                        // mr="1.3%"
                        // mb="1.3%"
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gridAutoRows="auto"
                        gap="2%"
                        >
                            {/* 1. Subteam */}
                            <Box
                                gridColumn="span 12"
                                // backgroundColor={colors.primary[400]}
                                display="flex"
                                alignItems="left"
                                justifyContent="left"
                            >
                                <Typography
                                    variant="h5"
                                    fontWeight="600"
                                    color='#FFD700'
                                >
                                    1. Subteam
                                </Typography>
                            </Box>
                            <Box
                                gridColumn="span 12"
                                // backgroundColor={colors.primary[400]}
                                display="block"
                                alignItems="left"
                                justifyContent="left"
                            >
                                <Typography 
                                variant="h5"
                                component="div"
                                sx={{ 
                                    display: 'block',
                                    whiteSpace: 'nowrap',
                                    textAlign: 'left',
                                    overflow: 'hidden',  
                                    textOverflow: 'ellipsis', 
                                    width: '100%' }}
                                    > </Typography>
                                <Box sx={{ minWidth: 350 }}>
                                    <FormControl required fullWidth size='small'>
                                        <InputLabel
                                            id="subteam-select-label"
                                            sx={{
                                                color: '#B2EBF2', // Cyan color for label
                                                '&.Mui-focused': {
                                                  color: '#B2EBF2' // Ensure label remains cyan when focused
                                                },
                                                '&.MuiInputLabel-shrink': {
                                                  color: '#B2EBF2' // Ensure label remains cyan when it shrinks
                                                },
                                              }}
                                        > 
                                            Select an option 
                                        </InputLabel>
                                        <Select
                                        value={subTeam}
                                        label="Select an Option"
                                        placeholder={subTeam !== undefined? subTeam: 'Select an option'}
                                        onChange={handleSubTeam}
                                        sx={{
                                            '& .MuiSelect-select': {
                                              textAlign: 'left', // Align text to the left
                                              color: '#B2EBF2',
                                              fontSize: '18px', // Text color
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#B2EBF2', // Border color
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#B2EBF2', // Border color on hover
                                            },
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#B2EBF2', // Border color when focused
                                            },
                                            backgroundColor: 'transparent', // Set a lighter background color
                                          }}

                                          MenuProps={{
                                            PaperProps: {
                                              sx: {
                                                backgroundColor: '#008080', // Teal background for the selection list
                                                '& .MuiMenuItem-root': {
                                                  color: '#FFFFFF',
                                                  fontSize: '18px', 
                                                },
                                                '& .MuiMenuItem-root:hover': {
                                                  backgroundColor: '#005f5f', // Darker teal on hover
                                                },
                                              },
                                            },
                                          }}
                                        >
                                            {subTeamSelectionArray !== undefined ? subTeamSelectionArray.map((option) => (
                                            <MenuItem key={option} value={option}>{option}</MenuItem>    
                                            ))
                                            :
                                            ''
                                            }
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Box>
                            {/* End Subteam */}
                            
                            {/* Strategic Direction */}
                            <Box
                                gridColumn="span 12"
                                // backgroundColor={colors.primary[400]}
                                display="flex"
                                alignItems="left"
                                justifyContent="left"
                            >
                                <Typography
                                    variant="h5"
                                    fontWeight="600"
                                    color='#FFD700'
                                >
                                    2. Strategic Direction
                                </Typography>
                            </Box>
                            <Box
                                gridColumn="span 12"
                                // backgroundColor={colors.primary[400]}
                                display="block"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Typography 
                                variant="h5"
                                component="div"
                                color='#FFD700'
                                sx={{ 
                                    display: 'block',
                                    whiteSpace: 'nowrap',
                                    textAlign: 'left',
                                    overflow: 'hidden',  
                                    textOverflow: 'ellipsis', 
                                    width: '100%' }}
                                >2.1 Strategic Direction CPF: 
                                </Typography>
                                <Box sx={{ minWidth: 350, marginTop:'10px'}}>
                                    <FormControl required fullWidth size='small'>
                                        <InputLabel
                                                id="subteam-select-label"
                                                sx={{
                                                    color: '#B2EBF2', // Cyan color for label
                                                    '&.Mui-focused': {
                                                    color: '#B2EBF2' // Ensure label remains cyan when focused
                                                    },
                                                    '&.MuiInputLabel-shrink': {
                                                    color: '#B2EBF2' // Ensure label remains cyan when it shrinks
                                                    },
                                                }}
                                            > 
                                            Select an option 
                                        </InputLabel>
                                        <Select
                                        value={directionCPF}
                                        label='Select an Option'
                                        placeholder={directionCPF != ''? directionCPF: 'Select One'}
                                        onChange={handleDirectionCPF}
                                        sx={{
                                            '& .MuiSelect-select': {
                                              textAlign: 'left', // Align text to the left
                                              color: '#B2EBF2', // Text color
                                              fontSize: '18px',
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#B2EBF2', // Border color
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#B2EBF2', // Border color on hover
                                            },
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#B2EBF2', // Border color when focused
                                            },
                                            backgroundColor: 'transparent', // Set a lighter background color
                                          }}
                                          MenuProps={{
                                            PaperProps: {
                                              sx: {
                                                backgroundColor: '#008080', // Teal background for the selection list
                                                '& .MuiMenuItem-root': {
                                                  color: '#FFFFFF', // Text color for the menu items
                                                  fontSize: '18px',
                                                },
                                                '& .MuiMenuItem-root:hover': {
                                                  backgroundColor: '#005f5f', // Darker teal on hover
                                                },
                                              },
                                            },
                                          }}

                                        >
                                            <MenuItem value="" disabled>Select an option</MenuItem>
                                            {directionCPFList.map((option) => (
                                            <MenuItem key={option} value={option}>{option}</MenuItem>
                                            ))}
                                            
                                        </Select>
                                    </FormControl>
                                </Box>                            
                            </Box>
                            <Box
                                gridColumn="span 12"
                                // backgroundColor={colors.primary[400]}
                                display="block"
                                alignItems="left"
                                justifyContent="left"
                            >
                                <Typography 
                                variant="h5"
                                component="div"
                                color='#FFD700'
                                sx={{ 
                                    display: 'block',
                                    whiteSpace: 'nowrap',
                                    textAlign: 'left',
                                    overflow: 'hidden',  
                                    textOverflow: 'ellipsis', 
                                    width: '100%' }}
                                >2.2 Strategic Direction Corporate Engineer: 
                                </Typography>
                                <Box sx={{ minWidth: 350, marginTop:'10px'}}>
                                    <FormControl required fullWidth size='small'>
                                        <InputLabel
                                            sx={{
                                                color: '#B2EBF2', // Cyan color for label
                                                '&.Mui-focused': {
                                                  color: '#B2EBF2' // Ensure label remains cyan when focused
                                                },
                                                '&.MuiInputLabel-shrink': {
                                                  color: '#B2EBF2' // Ensure label remains cyan when it shrinks
                                                },
                                              }}
                                        > 
                                            Select an option 
                                        </InputLabel>
                                        <Select
                                        value={directionCOE}
                                        label='Select an Option'
                                        placeholder={directionCOE != ''? directionCOE: 'Select One'}
                                        onChange={handleDirectionCOE}
                                        sx={{
                                            '& .MuiSelect-select': {
                                              textAlign: 'left', // Align text to the left
                                              color: '#B2EBF2', // Text color
                                              fontSize: '18px',
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#B2EBF2', // Border color
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#B2EBF2', // Border color on hover
                                            },
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#B2EBF2', // Border color when focused
                                            },
                                            backgroundColor: 'transparent', // Set a lighter background color
                                          }}
                                          MenuProps={{
                                            PaperProps: {
                                              sx: {
                                                backgroundColor: '#008080', // Teal background for the selection list
                                                '& .MuiMenuItem-root': {
                                                  color: '#FFFFFF', // Text color for the menu items
                                                  fontSize: '18px',
                                                },
                                                '& .MuiMenuItem-root:hover': {
                                                  backgroundColor: '#005f5f', // Darker teal on hover
                                                },
                                              },
                                            },
                                          }}

                                        >
                                            <MenuItem value="" disabled>
                                            Select an option
                                            </MenuItem>
                                            {directionCOEList.map((option) => (
                                            <MenuItem key={option} value={option}>{option}</MenuItem>
                                            ))}
                                            
                                        </Select>
                                    </FormControl>
                                </Box>
                            


                            </Box>
                            {/* End Strategic Direction */}

                            {/* Project Detail */}
                            <Box
                                gridColumn="span 12"
                                // backgroundColor={colors.primary[400]}
                                display="flex"
                                alignItems="left"
                                justifyContent="left"
                            >
                                <Typography
                                    variant="h5"
                                    fontWeight="600"
                                    color='#FFD700'
                                >
                                    3. Project Detail
                                </Typography>
                            </Box>
                            {/* 3.1 BU */}
                            <Box
                                gridColumn="span 12"
                                // backgroundColor={colors.primary[400]}
                                display="block"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Typography 
                                variant="h5"
                                component="div"
                                color='#FFD700' 
                                sx={{ 
                                    display: 'block',
                                    whiteSpace: 'nowrap',
                                    textAlign: 'left',
                                    overflow: 'hidden',  
                                    textOverflow: 'ellipsis', 
                                    width: '100%' }}
                                    >3.1 BU: </Typography>
                                <Box sx={{ minWidth: 250, marginTop:'10px' }}>
                                    <FormControl required fullWidth size='small'>
                                        <InputLabel
                                            sx={{
                                                color: '#B2EBF2', // Cyan color for label
                                                '&.Mui-focused': {
                                                  color: '#B2EBF2' // Ensure label remains cyan when focused
                                                },
                                                '&.MuiInputLabel-shrink': {
                                                  color: '#B2EBF2' // Ensure label remains cyan when it shrinks
                                                },
                                              }}
                                        > 
                                            Select an option 
                                        </InputLabel>

                                        <Select
                                        value={BU}
                                        label='Select an Option'
                                        placeholder={BU != ''? BU: 'Select One'}
                                        onChange={handleBU}
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        sx={{
                                            '& .MuiSelect-select': {
                                              textAlign: 'left', // Align text to the left
                                              color: '#B2EBF2', // Text color
                                              fontSize: '18px',
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#B2EBF2', // Border color
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#B2EBF2', // Border color on hover
                                            },
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#B2EBF2', // Border color when focused
                                            },
                                            backgroundColor: 'transparent', // Set a lighter background color
                                          }}
                                          MenuProps={{
                                            PaperProps: {
                                              sx: {
                                                backgroundColor: '#008080', // Teal background for the selection list
                                                '& .MuiMenuItem-root': {
                                                  color: '#FFFFFF', // Text color for the menu items
                                                  fontSize: '18px',
                                                },
                                                '& .MuiMenuItem-root:hover': {
                                                  backgroundColor: '#005f5f', // Darker teal on hover
                                                },
                                              },
                                            },
                                          }}
                                        >
                                            <MenuItem value="" disabled>
                                            Select an option
                                            </MenuItem>
                                            {bUList.map((option) => (
                                            <MenuItem key={option} value={option}>{option}</MenuItem>
                                            ))}
                                            
                                        </Select>
                                    </FormControl>
                                </Box>                           
                            </Box>
                            {/* 3.2 Location/Plant Name */}
                            <Box
                            gridColumn="span 12"
                            // backgroundColor={colors.primary[400]}
                            display="block"
                            alignItems="left"
                            justifyContent="left"
                            >
                                <Typography 
                                variant="h5"
                                component="div" 
                                color='#FFD700'
                                sx={{ 
                                    display: 'block',
                                    whiteSpace: 'nowrap',
                                    textAlign: 'left',
                                    overflow: 'hidden',  
                                    textOverflow: 'ellipsis', 
                                    width: '100%' }}
                                    >3.2 Plant Name/Location: </Typography>
                                <Box sx={{ minWidth: 250, marginTop:'10px' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '18px' }}>
                                    <input
                                        type="text"
                                        id="mui-textfield"
                                        value={location}
                                        onChange={handleLocation}
                                        placeholder="Enter location"
                                        style={{
                                        padding: '8px',
                                        width: '100%',
                                        border: '1px solid #B2EBF2', // Border color
                                        borderRadius: '4px',
                                        outline: 'none',
                                        fontSize: '18px', // Matches Material-UI TextField font size
                                        fontFamily: "CPF Imm Sook", // Matches Material-UI TextField font family
                                        color: '#B2EBF2', // Text color
                                        backgroundColor: 'transparent', // Set a lighter background color
                                        }}
                                    />
                                    </div>
                                </Box>
                            </Box>
                            {/* 3.3 Project Type */}
                            <Box
                            gridColumn="span 12"
                            // backgroundColor={colors.primary[400]}
                            display="block"
                            alignItems="left"
                            justifyContent="left"
                            >
                                <Typography
                                variant="h5"
                                 sx={{ 
                                    display: 'block',
                                    whiteSpace: 'nowrap',
                                    textAlign: 'left',
                                    overflow: 'hidden',  
                                    textOverflow: 'ellipsis', 
                                    width: '100%' }}
                                    color='#FFD700'
                                    >3.3 Project Type: </Typography>
                                <Box sx={{ minWidth: 250, marginTop:'10px' }}>
                                    <FormControl required fullWidth size='small'>
                                        <InputLabel
                                            sx={{
                                                color: '#B2EBF2', // Cyan color for label
                                                '&.Mui-focused': {
                                                color: '#B2EBF2', // Ensure label remains cyan when focused
                                                },
                                                '&.MuiInputLabel-shrink': {
                                                  color: '#B2EBF2' // Ensure label remains cyan when it shrinks
                                                },
                                              }}
                                        > 
                                            Select options
                                        </InputLabel>

                                        <Select
                                        value={projectType}
                                        multiple
                                        label='Select Options'
                                        placeholder={projectType != ''? projectType: 'Select options'}
                                        onChange={handleProjectType}
                                        renderValue={(selected) => (
                                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                              {selected.map((value) => (
                                                <Button key={value} style={{ marginRight: 8, backgroundColor:'#B2EBF2', borderRadius:'20px' }}>{value}</Button>
                                              ))}
                                            </div>
                                          )}
                                          sx={{
                                            '& .MuiSelect-select': {
                                              textAlign: 'left', // Align text to the left
                                              color: '#B2EBF2', // Text color
                                              fontSize: '18px',
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#B2EBF2', // Border color
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#B2EBF2', // Border color on hover
                                            },
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#B2EBF2', // Border color when focused
                                            },
                                            backgroundColor: 'transparent', // Set a lighter background color
                                          }}
                                          MenuProps={{
                                            PaperProps: {
                                              sx: {
                                                backgroundColor: '#008080', // Teal background for the selection list
                                                '& .MuiMenuItem-root': {
                                                  color: '#FFFFFF', // Text color for the menu items
                                                  fontSize: '18px',
                                                },
                                                '& .MuiMenuItem-root:hover': {
                                                  backgroundColor: '#005f5f', // Darker teal on hover
                                                },
                                              },
                                            },
                                          }}
                                        >
                                            {projectTypeList.map((option) => (
                                            <MenuItem key={option} value={option}>
                                                {option}
                                            </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Box>
                            {/* 3.4 Year */}
                            <Box
                            gridColumn="span 12"
                            // backgroundColor={colors.primary[400]}
                            display="block"
                            alignItems="left"
                            justifyContent="left"
                            >
                                <Typography 
                                variant="h5"
                                component="div" 
                                color='#FFD700'
                                sx={{ 
                                    display: 'block',
                                    whiteSpace: 'nowrap',
                                    textAlign: 'left',
                                    overflow: 'hidden',  
                                    textOverflow: 'ellipsis', 
                                    width: '100%' }}
                                    >3.4 Year: </Typography>
                                <Box sx={{ minWidth: 250, marginTop:'10px'}}>
                                    <FormControl required fullWidth size='small'>
                                        <InputLabel
                                            sx={{
                                                color: '#B2EBF2', // Cyan color for label
                                                '&.Mui-focused': {
                                                  color: '#B2EBF2' // Ensure label remains cyan when focused
                                                },
                                                '&.MuiInputLabel-shrink': {
                                                  color: '#B2EBF2' // Ensure label remains cyan when it shrinks
                                                },
                                              }}
                                        > 
                                            Selection an option 
                                        </InputLabel>
                                        <Select
                                        value={year}
                                        label='Select an Option'
                                        placeholder={year != ''? year: 'Select One'}
                                        onChange={handleYear}
                                        sx={{
                                            '& .MuiSelect-select': {
                                              textAlign: 'left', // Align text to the left
                                              color: '#B2EBF2', // Text color
                                              fontSize: '18px',
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#B2EBF2', // Border color
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#B2EBF2', // Border color on hover
                                            },
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#B2EBF2', // Border color when focused
                                            },
                                            backgroundColor: 'transparent', // Set a lighter background color
                                          }}
                                          MenuProps={{
                                            PaperProps: {
                                              sx: {
                                                backgroundColor: '#008080', // Teal background for the selection list
                                                '& .MuiMenuItem-root': {
                                                  color: '#FFFFFF', // Text color for the menu items
                                                  fontSize: '18px',
                                                },
                                                '& .MuiMenuItem-root:hover': {
                                                  backgroundColor: '#005f5f', // Darker teal on hover
                                                },
                                              },
                                            },
                                          }}
                                        >
                                            <MenuItem value="" disabled>
                                            Select an option
                                            </MenuItem>
                                            {yearList.map((option) => (
                                            <MenuItem key={option} value={option}>{option}</MenuItem>
                                            ))}
                                            
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Box>
                            {/* 3.5 Project Name */}
                            <Box
                            gridColumn="span 12"
                            // backgroundColor={colors.primary[400]}
                            display="block"
                            alignItems="left"
                            justifyContent="left"
                            >
                                <Typography 
                                variant="h5"
                                component="div"
                                color='#FFD700'
                                sx={{ 
                                    display: 'block',
                                    whiteSpace: 'nowrap',
                                    textAlign: 'left',
                                    overflow: 'hidden',  
                                    textOverflow: 'ellipsis', 
                                    width: '100%' }}
                                    >3.5 Project Name: </Typography>
                               
                               <Box sx={{ minWidth: 250, marginTop:'10px'}}>
                                    <div>
                                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '18px' }}>
                                            <input
                                                type="text"
                                                id="mui-textfield"
                                                value={projectName}
                                                onChange={handleProjectName}
                                                placeholder="Enter project name"
                                                style={{
                                                    padding: '8px',
                                                    width: '100%',
                                                    border: '1px solid #B2EBF2', // Border color
                                                    borderRadius: '4px',
                                                    outline: 'none',
                                                    fontSize: '18px', // Matches Material-UI TextField font size
                                                    fontFamily: "CPF Imm Sook", // Matches Material-UI TextField font family
                                                    color: '#B2EBF2', // Text color
                                                    backgroundColor: 'transparent', // Set a lighter background color
                                                }}
                                            />
                                        </div>
                                    </div>
                                </Box>
                                
                            </Box>
                            {/* 3.6 Background */}
                            <Box
                            gridColumn="span 12"
                            // backgroundColor={colors.primary[400]}
                            display="block"
                            alignItems="left"
                            justifyContent="left"
                            >
                                <Typography 
                                variant="h5"
                                component="div"
                                color='#FFD700' 
                                sx={{ 
                                    display: 'block',
                                    whiteSpace: 'nowrap',
                                    textAlign: 'left',
                                    overflow: 'hidden',  
                                    textOverflow: 'ellipsis', 
                                    width: '100%' }}
                                    >3.6 Background: </Typography>
                               
                               <Box sx={{ minWidth: 250, marginTop:'10px'}}>
                                    <div>
                                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '18px' }}>
                                        <textarea
                                            id="mui-textfield"
                                            value={projectBackground}
                                            onChange={handleProjectBackground}
                                            placeholder={projectBackground !== '' ? projectBackground : 'Enter background'}
                                            style={{
                                                padding: '8px',
                                                width: '100%',
                                                height: '100px',
                                                border: '1px solid #B2EBF2', // Border color
                                                borderRadius: '4px',
                                                outline: 'none',
                                                fontSize: '18px', // Matches Material-UI TextField font size
                                                fontFamily: "CPF Imm Sook", // Matches Material-UI TextField font family
                                                boxSizing: 'border-box',
                                                resize: 'none', // Prevents resizing
                                                overflow: 'auto',
                                                color: '#B2EBF2', // Text color
                                                backgroundColor: 'transparent', // Set a lighter background color

                                            }}
                                        />
                                        </div>
                                    </div>
                                </Box>
                                
                            </Box>
                            {/* 3.7 Detail */}
                            <Box
                            gridColumn="span 12"
                            // backgroundColor={colors.primary[400]}
                            display="block"
                            alignItems="left"
                            justifyContent="left"
                            >
                                <Typography 
                                variant="h5"
                                component="div" 
                                color='#FFD700'
                                sx={{ 
                                    display: 'block',
                                    whiteSpace: 'nowrap',
                                    textAlign: 'left',
                                    overflow: 'hidden',  
                                    textOverflow: 'ellipsis', 
                                    width: '100%' }}
                                    >3.7 Detail: </Typography>
                               
                               <Box sx={{ minWidth: 250, marginTop:'10px'}}>
                                    <div>
                                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '18px' }}>
                                            <textarea
                                                id="mui-textfield"
                                                value={projectDetail}
                                                onChange={handleProjectDetail}
                                                placeholder={projectDetail !== '' ? projectDetail : 'Enter detail'}
                                                style={{
                                                    padding: '8px',
                                                    width: '100%',
                                                    height: '100px',
                                                    border: '1px solid #B2EBF2', // Border color
                                                    borderRadius: '4px',
                                                    outline: 'none',
                                                    fontSize: '18px', // Matches Material-UI TextField font size
                                                    fontFamily: "CPF Imm Sook", // Matches Material-UI TextField font family
                                                    boxSizing: 'border-box',
                                                    resize: 'none', // Prevents resizing
                                                    overflow: 'auto',
                                                    color: '#B2EBF2', // Text color
                                                    backgroundColor: 'transparent', // Set a lighter background color
                                                }}
                                            />
                                        </div>
                                    </div>
                                </Box>
                                
                            </Box>
                            {/* 3.8 Budget */}
                            <Box
                            gridColumn="span 12"
                            // backgroundColor={colors.primary[400]}
                            display="block"
                            alignItems="left"
                            justifyContent="left"
                            >
                                <Typography 
                                variant="h5"
                                component="div" 
                                color='#FFD700'
                                sx={{ 
                                    display: 'block',
                                    whiteSpace: 'nowrap',
                                    textAlign: 'left',
                                    overflow: 'hidden',  
                                    textOverflow: 'ellipsis', 
                                    width: '100%' }}
                                    >3.8 Budget: </Typography>
                               
                               <Box sx={{ minWidth: 250, marginTop:'10px'}}>
                                    <div>
                                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '18px' }}>
                                            <textarea
                                                id="mui-textfield"
                                                value={budget}
                                                onChange={handleBudget}
                                                placeholder={budget !== '' ? budget : 'Enter budget'}
                                                onKeyPress={(e) => {
                                                    // Allow only digits, dot, and backspace
                                                    const isValidChar = /[0-9.]/.test(e.key);
                                                    if (!isValidChar) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                style={{
                                                    padding: '8px',
                                                    width: '100%',
                                                    height: '100px',
                                                    border: '1px solid #B2EBF2', // Border color
                                                    borderRadius: '4px',
                                                    outline: 'none',
                                                    fontSize: '18px', // Matches Material-UI TextField font size
                                                    fontFamily: "CPF Imm Sook", // Matches Material-UI TextField font family
                                                    boxSizing: 'border-box',
                                                    resize: 'none', // Prevents resizing
                                                    overflow: 'auto',
                                                    color: '#B2EBF2', // Text color
                                                    backgroundColor: 'transparent', // Set a lighter background color
                                                }}
                                            />
                                        </div>
                                    </div>
                                </Box>
                                
                            </Box>
                            {/* 3.9 Project Manager */}
                            <Box
                            gridColumn="span 12"
                            // backgroundColor={colors.primary[400]}
                            display="block"
                            alignItems="left"
                            justifyContent="left"
                            >
                                <Typography 
                                variant="h5"
                                component="div" 
                                color='#FFD700'
                                sx={{ 
                                    display: 'block',
                                    whiteSpace: 'nowrap',
                                    textAlign: 'left',
                                    overflow: 'hidden',  
                                    textOverflow: 'ellipsis', 
                                    width: '100%' }}
                                    >3.9 Project Manager: </Typography>
                                <Box sx={{ minWidth: 250, marginTop:'10px' }}>
                                    <div>
                                        <div style={{ display: 'flex', alignItems: 'right', marginBottom: '18px' }}>
                                            {
                                                projectManager !== '' ?
                                                <>
                                                    <Typography color='#B2EBF2'>
                                                        {projectManager}
                                                    </Typography>
                                                    <IconButton
                                                    color="error"
                                                    aria-label="delete"
                                                    onClick={handleDeleteManager} // Add your delete logic here
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </>
                                                
                                                :
                                                <SearchBar subPath={subPath} setMemberListJSON={setMemberListJSON} tabValue={value} setProjectManager={setProjectManager}/>
                                            }
                                            
                                        </div>
                                    </div>
                                </Box>
                            </Box>
                            {/* 3.10 Project Category */}
                            <Box
                            gridColumn="span 12"
                            // backgroundColor={colors.primary[400]}
                            display="block"
                            alignItems="left"
                            justifyContent="left"
                            >
                                <Typography 
                                variant="h5"
                                component="div"
                                color='#FFD700'
                                sx={{ 
                                    display: 'block',
                                    whiteSpace: 'nowrap',
                                    textAlign: 'left',
                                    overflow: 'hidden',  
                                    textOverflow: 'ellipsis', 
                                    width: '100%' }}
                                    >3.10 Category: </Typography>
                                <Box sx={{ minWidth: 350, marginTop:'10px' }}>
                                    <FormControl required fullWidth size='small'>
                                        <InputLabel
                                            sx={{
                                                color: '#B2EBF2', // Cyan color for label
                                                '&.Mui-focused': {
                                                  color: '#B2EBF2' // Ensure label remains cyan when focused
                                                },
                                                '&.MuiInputLabel-shrink': {
                                                  color: '#B2EBF2' // Ensure label remains cyan when it shrinks
                                                },
                                              }}
                                        > 
                                            Selection an option 
                                        </InputLabel>
                                        <Select
                                        value={category}
                                        label='Select an Option'
                                        placeholder={category != ''? category: 'Select One'}
                                        onChange={handleCategory}
                                        sx={{
                                            '& .MuiSelect-select': {
                                              textAlign: 'left', // Align text to the left
                                              color: '#B2EBF2', // Text color
                                              fontSize: '18px',
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#B2EBF2', // Border color
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#B2EBF2', // Border color on hover
                                            },
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#B2EBF2', // Border color when focused
                                            },
                                            backgroundColor: 'transparent', // Set a lighter background color
                                          }}
                                          MenuProps={{
                                            PaperProps: {
                                              sx: {
                                                backgroundColor: '#008080', // Teal background for the selection list
                                                '& .MuiMenuItem-root': {
                                                  color: '#FFFFFF', // Text color for the menu items
                                                  fontSize: '18px',
                                                },
                                                '& .MuiMenuItem-root:hover': {
                                                  backgroundColor: '#005f5f', // Darker teal on hover
                                                },
                                              },
                                            },
                                          }}

                                        >
                                            <MenuItem value="" disabled>
                                            Select an option
                                            </MenuItem>
                                            {categoryList.map((option) => (
                                            <MenuItem key={option} value={option}>{option}</MenuItem>
                                            ))}
                                            
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Box>
                            {/* 3.11 Topic KPI */}
                            <Box
                            gridColumn="span 12"
                            // backgroundColor={colors.primary[400]}
                            display="block"
                            alignItems="left"
                            justifyContent="left"
                            >
                                <Typography 
                                variant="h5"
                                component="div" 
                                color='#FFD700'
                                sx={{ 
                                    display: 'block',
                                    whiteSpace: 'nowrap',
                                    textAlign: 'left',
                                    overflow: 'hidden',  
                                    textOverflow: 'ellipsis', 
                                    width: '100%' }}
                                    >3.11 Topic KPI: </Typography>
                                <Box sx={{ minWidth: 350, marginTop:'10px' }}>
                                    <div>
                                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '18px' }}>
                                            <textarea
                                                id="mui-textfield"
                                                value={topicKPI}
                                                onChange={handleTopicKPI}
                                                placeholder={topicKPI !== '' ? topicKPI : 'Enter KPI'}
                                                style={{
                                                    padding: '8px',
                                                    width: '100%',
                                                    height: '100px',
                                                    border: '1px solid #B2EBF2', // Border color
                                                    borderRadius: '4px',
                                                    outline: 'none',
                                                    fontSize: '18px', // Matches Material-UI TextField font size
                                                    fontFamily: "CPF Imm Sook", // Matches Material-UI TextField font family
                                                    boxSizing: 'border-box',
                                                    resize: 'none', // Prevents resizing
                                                    overflow: 'auto',
                                                    color: '#B2EBF2', // Text color
                                                    backgroundColor: 'transparent', // Set a lighter background color
                                                }}
                                            />
                                        </div>
                                    </div>
                                </Box>
                            </Box>
                            {/* 3.12 Start Date */}
                            <Box
                            gridColumn="span 12"
                            // backgroundColor={colors.primary[400]}
                            display="flex"
                            alignItems="left"
                            justifyContent="left"
                            >
                                <Typography 
                                variant="h5"
                                component="div" 
                                color='#FFD700'
                                sx={{ 
                                    display: 'block',
                                    whiteSpace: 'nowrap',
                                    textAlign: 'left',
                                    overflow: 'hidden',  
                                    textOverflow: 'ellipsis', 
                                    width: '100%' }}
                                    >3.12 Start Date: </Typography>
                                <Box sx={{ minWidth: 350, textAlign: 'right' }}>
                                    <DatePicker
                                        selected={selectedStartDate}
                                        onChange={handleStartDateChange}
                                        dateFormat="MM/dd/yyyy"
                                        placeholderText="Select a date"
                                        className="custom-datepicker"
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        style={{
                                            padding: '8px',
                                            border: '1px solid rgba(0, 0, 0, 0.23)',
                                            borderRadius: '4px',
                                            outline: 'none',
                                            fontSize: '18px', // Match TextField font size
                                            fontFamily: "CPF Imm Sook", // Match TextField font family
                                            width: '100%',
                                            color: '#B2EBF2'
                                        }}
                                        calendarClassName="custom-datepicker-calendar"
                                    />
                                </Box>
                            </Box>
                            {/* 3.13 End Date */}
                            <Box
                            gridColumn="span 12"
                            // backgroundColor={colors.primary[400]}
                            display="flex"
                            alignItems="left"
                            justifyContent="left"
                            >
                                <Typography 
                                variant="h5"
                                component="div" 
                                color='#FFD700'
                                sx={{ 
                                    display: 'block',
                                    whiteSpace: 'nowrap',
                                    textAlign: 'left',
                                    overflow: 'hidden',  
                                    textOverflow: 'ellipsis', 
                                    width: '100%' }}
                                    >3.13 End Date: </Typography>
                                <Box sx={{ minWidth: 350, textAlign: 'right' }}>
                                    <DatePicker
                                        size='small'
                                        selected={selectedEndDate}
                                        onChange={handleEndDateChange}
                                        dateFormat="MM/dd/yyyy"
                                        placeholderText="Select a date"
                                        className="custom-datepicker"
                                        style={{
                                            padding: '8px',
                                            border: '1px solid rgba(0, 0, 0, 0.23)',
                                            borderRadius: '4px',
                                            outline: 'none',
                                            fontSize: '18px', // Match TextField font size
                                            fontFamily: "CPF Imm Sook", // Match TextField font family
                                            width: '100%',
                                        }}
                                        calendarClassName="custom-datepicker-calendar"
                                    />
                                </Box>
                            </Box>
                            {/* End Project Detail */}

                            {/* Submit */}
                            <Box
                                gridColumn="span 12"
                                // backgroundColor={colors.primary[400]}
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Box sx={{ textAlign: 'center' }}>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            color: '#000000', // Text color
                                            backgroundColor: '#FFD700', // Background color
                                            '&:hover': {
                                            backgroundColor: '#FFD700', // Background color on hover
                                            },
                                        }}
                                        onClick={handleSubmitProjectDetail}
                                    >
                                            Save
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                       
                    </TabPanel>
                    
                    {/* TAB 2 */}
                    <TabPanel value="2">
                        <Box sx={{ width: '100%', typography: 'body1' }}>
                            {/* MEMBER LIST */}
                            <Grid item xs={isMediumScreen ? 12 : 8}>
                            <SearchBar subPath={subPath} setMemberListJSON={setMemberListJSON} tabValue={value} setProjectManager={setProjectManager}/>
                            </Grid>
                        </Box>
                        <Box>
                        <Table aria-labelledby="tableTitle"> 
                            <TableHead style={{backgroudColor:'black'}}>
                                <TableRow>
                                    <TableCell style={{ color: '#B2EBF2' }}>Name</TableCell>
                                    <TableCell style={{ color: '#B2EBF2' }}>Role</TableCell>
                                    <TableCell style={{ color: '#B2EBF2' }}>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {memberListJSON !== undefined ? memberListJSON
                                .map((member) => (
                                <TableRow>
                                    <TableCell style={{ color: '#B2EBF2' }}>
                                    <Box
                                        sx={{
                                        alignItems: 'center',
                                        display: 'flex'
                                        }}
                                    >
                                        <Typography style={{ color: '#B2EBF2' }} variant="h6">
                                        {member.first_name} {member.last_name}
                                        </Typography>
                                    </Box>
                                    </TableCell>
                                    <TableCell style={{ color: '#B2EBF2', fontSize: '18px', }}>{member.role}</TableCell>
                                    <TableCell style={{ color: '#B2EBF2', fontSize: '18px', }}>
                                    <Button
                                    accessKey={member.name}
                                    onClick={e=>handleDeleteMember(member.project_name, member.first_name, member.last_name)}
                                    variant="contained"
                                    color="error"
                                    >
                                    Delete
                                    </Button>
                                    </TableCell>
                                </TableRow>
                                )): ''}
                            </TableBody>
                        </Table>
                        </Box>
                    </TabPanel>

                    {/* TAB 3 */}
                    <TabPanel value="3">
                        <Box sx={{ width: '100%', typography: 'body1' }}>
                            {/* 1. Cost Saving*/}
                            <Box
                                gridColumn="span 12"
                                // backgroundColor={colors.primary[400]}
                                display="flex"
                                alignItems="left"
                                justifyContent="left"
                            >
                                <Typography
                                    variant="h5"
                                    fontWeight="600"
                                    color='#FFD700'
                                >
                                    1. Cost Saving
                                </Typography>
                            </Box>
                            {/* 1.1 Saving Type */}
                            <Box
                                gridColumn="span 12"
                                // backgroundColor={colors.primary[400]}
                                display="flex"
                                alignItems="left"
                                justifyContent="left"
                            >
                                <Typography 
                                variant="h5"
                                component="div"
                                color='#FFD700'
                                sx={{ 
                                    display: 'block',
                                    whiteSpace: 'nowrap',
                                    textAlign: 'left',
                                    overflow: 'hidden',  
                                    textOverflow: 'ellipsis', 
                                    width: '100%' }}
                                    >1.1 Type: </Typography>
                                <Box sx={{ minWidth: 350 }}>
                                    <FormControl required fullWidth size='small'>
                                        <InputLabel
                                            sx={{
                                                color: '#B2EBF2', // Cyan color for label
                                                '&.Mui-focused': {
                                                  color: '#B2EBF2' // Ensure label remains cyan when focused
                                                },
                                                '&.MuiInputLabel-shrink': {
                                                  color: '#B2EBF2' // Ensure label remains cyan when it shrinks
                                                },
                                              }}
                                        > 
                                            Selection an option 
                                        </InputLabel>
                                        <Select
                                        value={costSavingType}
                                        label="Select an Option"
                                        placeholder={costSavingType != ''? costSavingType: 'Select an option'}
                                        onChange={handleCostSavingType}
                                        sx={{
                                            '& .MuiSelect-select': {
                                              textAlign: 'left', // Align text to the left
                                              color: '#B2EBF2', // Text color
                                              fontSize: '18px',
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#B2EBF2', // Border color
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#B2EBF2', // Border color on hover
                                            },
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#B2EBF2', // Border color when focused
                                            },
                                            backgroundColor: 'transparent', // Set a lighter background color
                                          }}
                                          MenuProps={{
                                            PaperProps: {
                                              sx: {
                                                backgroundColor: '#008080', // Teal background for the selection list
                                                '& .MuiMenuItem-root': {
                                                  color: '#FFFFFF', // Text color for the menu items
                                                  fontSize: '18px',
                                                },
                                                '& .MuiMenuItem-root:hover': {
                                                  backgroundColor: '#005f5f', // Darker teal on hover
                                                },
                                              },
                                            },
                                          }}

                                        >
                                            {costSavingTypeList !== undefined ? costSavingTypeList.map((option) => (
                                            <MenuItem key={option} value={option}>{option}</MenuItem>    
                                            ))
                                            :
                                            ''
                                            }
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Box>
                            {/* 1.2 Start Saving */}
                            <Box
                            gridColumn="span 12"
                            // backgroundColor={colors.primary[400]}
                            display="flex"
                            alignItems="left"
                            justifyContent="left"
                            >
                                <Typography 
                                variant="h5"
                                component="div" 
                                color='#FFD700'
                                sx={{ 
                                    display: 'block',
                                    whiteSpace: 'nowrap',
                                    textAlign: 'left',
                                    overflow: 'hidden',  
                                    textOverflow: 'ellipsis', 
                                    width: '100%' }}
                                    >1.2 Start Saving Month: </Typography>
                                <Box sx={{ minWidth: 350, textAlign: 'right' }}>
                                    <DatePicker
                                        size='small'
                                        selected={startSaving}
                                        onChange={handleStartSaving}
                                        dateFormat="yyyy-MM"
                                        placeholderText="Select a month"
                                        className="custom-datepicker"
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        style={{
                                            padding: '8px',
                                            border: '1px solid rgba(0, 0, 0, 0.23)',
                                            borderRadius: '4px',
                                            outline: 'none',
                                            fontSize: '18px', // Match TextField font size
                                            fontFamily: "CPF Imm Sook", // Match TextField font family
                                            width: '100%',
                                            color: '#B2EBF2',
                                        }}
                                        calendarClassName="custom-datepicker-calendar"
                                    />
                                </Box>
                            </Box>
                            {/* 1.3 Saving 12 month */}
                            <Table aria-labelledby="tableTitle"> 
                                <TableHead style={{backgroudColor:'black'}}>
                                    <TableRow>
                                        <TableCell style={{ color: '#B2EBF2' }}>Month</TableCell>
                                        <TableCell style={{ color: '#B2EBF2' }}>Estimated saving(MB)</TableCell>
                                        <TableCell style={{ color: '#B2EBF2' }}>Actual saving (MB)</TableCell>
                                    </TableRow>
                                </TableHead>
                                {costSavingArrayList.length === 0?
                                    <TableBody>
                                    {savingMonthYearList !== undefined ? savingMonthYearList
                                        .map((list) => (
                                        <TableRow>
                                            <TableCell style={{ color: '#FFD700', fontSize: '18px', }}>
                                            <Box
                                                sx={{
                                                alignItems: 'center',
                                                display: 'flex'
                                                }}
                                            >
                                                <Typography color="#FFD700" variant="h6">
                                                {list.month}
                                                </Typography>
                                            </Box>
                                            </TableCell>
                                            <TableCell style={{ color: '#B2EBF2', fontSize: '18px', }}>
                                                <input
                                                    type="text"
                                                    id="mui-textfield"
                                                    placeholder="Enter target saving"
                                                    onChange={(e) => handleTargetSavingMonthYearArray(list.month, e.target.value)}
                                                    onKeyPress={(e) => {
                                                        // Allow only digits, dot, and backspace
                                                        const isValidChar = /[0-9.]/.test(e.key);
                                                        if (!isValidChar) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    style={{
                                                        padding: '8px',
                                                        width: '100%',
                                                        border: '1px solid rgba(0, 0, 0, 0.23)',
                                                        borderRadius: '4px',
                                                        outline: 'none',
                                                        fontSize: '18px', // Matches Material-UI TextField font size
                                                        fontFamily: "CPF Imm Sook", // Matches Material-UI TextField font family
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell style={{ color: '#B2EBF2', fontSize: '18px', }}>
                                                <input
                                                    type="text"
                                                    id="mui-textfield"
                                                    placeholder="Enter actual saving"
                                                    onChange={(e) => handleActualSavingMonthYearArray(list.month, e.target.value)}
                                                    onKeyPress={(e) => {
                                                        // Allow only digits, dot, and backspace
                                                        const isValidChar = /[0-9.]/.test(e.key);
                                                        if (!isValidChar) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    style={{
                                                        padding: '8px',
                                                        width: '100%',
                                                        border: '1px solid rgba(0, 0, 0, 0.23)',
                                                        borderRadius: '4px',
                                                        outline: 'none',
                                                        fontSize: '18px', // Matches Material-UI TextField font size
                                                        fontFamily: "CPF Imm Sook", // Matches Material-UI TextField font family
                                                    }}
                                                />
                                            </TableCell>
                                        </TableRow>
                                        )): ''}
                                    </TableBody>
                                :
                                <TableBody>
                                {costSavingArrayList
                                    .map((list) => (
                                    <TableRow>
                                        <TableCell style={{ color: '#B2EBF2' }}>
                                            <Box
                                                sx={{
                                                alignItems: 'center',
                                                display: 'flex'
                                                }}
                                            >
                                                <Typography color='80CBC4' variant="body1">
                                                {list.month_year}
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell style={{ color: '#B2EBF2' }}>
                                            <input
                                                type="text"
                                                id="mui-textfield"
                                                // value={list.target_saving_mb}
                                                placeholder={list.target_saving_mb}
                                                onChange={(e) => handleTargetSavingMonthYearArray(list.month_year, e.target.value)}
                                                onKeyPress={(e) => {
                                                    // Allow only digits, dot, and backspace
                                                    const isValidChar = /[0-9.]/.test(e.key);
                                                    if (!isValidChar) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                style={{
                                                    padding: '8px',
                                                    width: '100%',
                                                    border: '1px solid #B2EBF2', // Border color
                                                    borderRadius: '4px',
                                                    outline: 'none',
                                                    fontSize: '18px', // Matches Material-UI TextField font size
                                                    fontFamily: "CPF Imm Sook", // Matches Material-UI TextField font family
                                                    color: '#B2EBF2', // Text color
                                                    backgroundColor: 'transparent', // Set a lighter background color
            
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell style={{ color: '#B2EBF2' }}>
                                            <input
                                                type="text"
                                                id="mui-textfield"
                                                // value={list.actual_saving_mb}
                                                placeholder={list.actual_saving_mb}
                                                onChange={(e) => handleActualSavingMonthYearArray(list.month_year, e.target.value)}
                                                onKeyPress={(e) => {
                                                    // Allow only digits, dot, and backspace
                                                    const isValidChar = /[0-9.]/.test(e.key);
                                                    if (!isValidChar) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                style={{
                                                    padding: '8px',
                                                    width: '100%',
                                                    border: '1px solid #B2EBF2', // Border color
                                                    borderRadius: '4px',
                                                    outline: 'none',
                                                    fontSize: '18px', // Matches Material-UI TextField font size
                                                    fontFamily: "CPF Imm Sook", // Matches Material-UI TextField font family
                                                    color: '#B2EBF2', // Text color
                                                    backgroundColor: 'transparent', // Set a lighter background color
            
                                                }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                                }                                
                            </Table>
                            {/* Save Button */}
                            <Box sx={{ textAlign: 'center' }}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        color: '#000000', // Text color
                                        backgroundColor: '#FFD700', // Background color
                                        '&:hover': {
                                        backgroundColor: '#FFD700', // Background color on hover
                                        },
                                    }}
                                    onClick={handleSubmitTargetAndCostSavingArrays}
                                >
                                        Save
                                </Button>
                            </Box>
                        </Box>
                    </TabPanel>

                    {/* TAB 4 */}
                    <TabPanel value="4">
                        <Box>
                            {/* Progress*/}
                            <Box
                                gridColumn="span 12"
                                // backgroundColor={colors.primary[400]}
                                display="flex"
                                alignItems="left"
                                justifyContent="left"
                            >
                                <Typography
                                    variant="h5"
                                    fontWeight="600"
                                    color='#FFD700'
                                >
                                    Project Progress
                                </Typography>
                            </Box>
                        </Box>
                        <div>
                            <IconButton
                                sx={{
                                    backgroundColor: '#009688', // Cyan button color
                                    color: '#FFFFFF', // White icon color
                                    '&:hover': {
                                      backgroundColor: '#00796B', // Darker cyan on hover
                                    },
                                  }} 
                                onClick={handleToggle}
                            >
                                {expanded ? <ExpandLessIcon /> : <AddIcon />}
                            </IconButton>
                            <Collapse in={expanded}>
                                <Box sx={{ width: '100%', typography: 'body1' }}>
                                    {/* Status */}
                                    <Box
                                        gridColumn="span 12"
                                        // backgroundColor={colors.primary[400]}
                                        display="flex"
                                        alignItems="left"
                                        justifyContent="left"
                                    >
                                        <Typography 
                                        variant="h5"
                                        component="div"
                                        color='#FFD700'
                                        sx={{ 
                                            display: 'block',
                                            whiteSpace: 'nowrap',
                                            textAlign: 'left',
                                            overflow: 'hidden',  
                                            textOverflow: 'ellipsis', 
                                            width: '100%' }}
                                            >Status: </Typography>
                                        <Box sx={{ minWidth: 350 }}>
                                            <FormControl required fullWidth size='small'>
                                                <InputLabel
                                                sx={{
                                                    color: '#B2EBF2', // Cyan color for label
                                                    '&.Mui-focused': {
                                                    color: '#B2EBF2', // Ensure label remains cyan when focused
                                                    fontSize: '18px',
                                                    },
                                                    '&.MuiInputLabel-shrink': {
                                                    color: '#B2EBF2' // Ensure label remains cyan when it shrinks
                                                    },
                                                }}
                                            > 
                                                Selection an option 
                                            </InputLabel>

                                                <Select
                                                value={status}
                                                label="Select an Option"
                                                placeholder={subTeam != ''? subTeam: 'Select an option'}
                                                onChange={handleSetStatus}
                                                sx={{
                                                    '& .MuiSelect-select': {
                                                      textAlign: 'left', // Align text to the left
                                                      color: '#B2EBF2', // Text color
                                                      fontSize: '18px',
                                                    },
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                      borderColor: '#B2EBF2', // Border color
                                                    },
                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                      borderColor: '#B2EBF2', // Border color on hover
                                                    },
                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                      borderColor: '#B2EBF2', // Border color when focused
                                                    },
                                                    backgroundColor: 'transparent', // Set a lighter background color
                                                  }}
                                                  MenuProps={{
                                                    PaperProps: {
                                                      sx: {
                                                        backgroundColor: '#008080', // Teal background for the selection list
                                                        '& .MuiMenuItem-root': {
                                                          color: '#FFFFFF', // Text color for the menu items
                                                          fontSize: '18px',
                                                        },
                                                        '& .MuiMenuItem-root:hover': {
                                                          backgroundColor: '#005f5f', // Darker teal on hover
                                                        },
                                                      },
                                                    },
                                                  }}
        
                                                >
                                                    {statusList !== undefined ? statusList.map((option) => (
                                                    <MenuItem key={option} value={option}>{option}</MenuItem>    
                                                    ))
                                                    :
                                                    ''
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Box>

                                    {/* Percent Progress */}
                                    <Box
                                        gridColumn="span 12"
                                        display="flex"
                                        alignItems="left"
                                        justifyContent="left"
                                    >
                                        <Typography 
                                        variant="h5"
                                        component="div"
                                        color='#FFD700'
                                        sx={{ 
                                            display: 'block',
                                            whiteSpace: 'nowrap',
                                            textAlign: 'left',
                                            overflow: 'hidden',  
                                            textOverflow: 'ellipsis', 
                                            width: '100%' }}
                                        >
                                        Percent progress:
                                        </Typography>
                                        <Box sx={{ minWidth: 350 }}>
                                        <Slider
                                            value={percentProgress}
                                            onChange={handleSetPercentProgress}
                                            aria-labelledby="continuous-slider"
                                            min={0}
                                            max={100}
                                            valueLabelDisplay="auto"
                                            sx={{
                                            color: '#FFD700', // Change the color to your desired color
                                            '& .MuiSlider-rail': {
                                                opacity: 0.5, // Adjust rail opacity
                                            },
                                            '& .MuiSlider-thumb': {
                                                backgroundColor: '#FFD700', // Change thumb color
                                            },
                                            }}
                                        />
                                        </Box>
                                    </Box>

                                    {/* Circular Progress */}
                                    <Box
                                        gridColumn="span 12"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Box sx={{ position: 'relative', width: 150, height: 150 }}>
                                        <CircularProgress
                                            variant="determinate"
                                            value={percentProgress}
                                            size={150}
                                            thickness={5} // Adjust the thickness of the circle
                                            sx={{
                                            color: '#B2EBF2', // Change the color to your desired color
                                            '& .MuiCircularProgress-circle': {
                                                strokeLinecap: 'round', // Round line cap for a smoother look
                                            },
                                            }}
                                        />
                                        <Typography
                                            variant="h4"
                                            sx={{
                                            color: '#FFD700',
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            }}
                                        >
                                            {`${percentProgress}%`}
                                        </Typography>
                                        </Box>
                                    </Box>

                                    <Box>
                                        {/* Project Update */}
                                        <div>
                                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '18px' }}>
                                                <textarea
                                                    id="mui-textfield"
                                                    value={progressUpdate}
                                                    onChange={handleSetProgressUpdate}
                                                    placeholder={progressUpdate !== '' ? progressUpdate : 'Enter Progress Update'}
                                                    style={{
                                                        padding: '8px',
                                                        width: '100%',
                                                        height: '50px',
                                                        border: '1px solid #B2EBF2', // Border color
                                                        borderRadius: '4px',
                                                        outline: 'none',
                                                        fontSize: '18px', // Matches Material-UI TextField font size
                                                        fontFamily: "CPF Imm Sook", // Matches Material-UI TextField font family
                                                        boxSizing: 'border-box',
                                                        resize: 'none', // Prevents resizing
                                                        overflow: 'auto',
                                                        color: '#B2EBF2', // Text color
                                                        backgroundColor: 'transparent', // Set a lighter background color
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        {/* Next Step */}
                                        <div>
                                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '18px' }}>
                                                <textarea
                                                    id="mui-textfield"
                                                    value={nextStep}
                                                    onChange={handleSetNextStep}
                                                    placeholder={nextStep !== '' ? nextStep : 'Enter Next Step'}
                                                    style={{
                                                        padding: '8px',
                                                        width: '100%',
                                                        height: '50px',
                                                        border: '1px solid #B2EBF2', // Border color
                                                        borderRadius: '4px',
                                                        outline: 'none',
                                                        fontSize: '18px', // Matches Material-UI TextField font size
                                                        fontFamily: "CPF Imm Sook", // Matches Material-UI TextField font family
                                                        boxSizing: 'border-box',
                                                        resize: 'none', // Prevents resizing
                                                        overflow: 'auto',
                                                        color: '#B2EBF2', // Text color
                                                        backgroundColor: 'transparent', // Set a lighter background color
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        {/* Problem */}
                                        <div>
                                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '18px' }}>
                                                <textarea
                                                    id="mui-textfield"
                                                    value={problem}
                                                    onChange={handleSetProblem}
                                                    placeholder={problem !== '' ? problem : 'Enter Problem'}
                                                    style={{
                                                        padding: '8px',
                                                        width: '100%',
                                                        height: '50px',
                                                        border: '1px solid #B2EBF2', // Border color
                                                        borderRadius: '4px',
                                                        outline: 'none',
                                                        fontSize: '18px', // Matches Material-UI TextField font size
                                                        fontFamily: "CPF Imm Sook", // Matches Material-UI TextField font family
                                                        boxSizing: 'border-box',
                                                        resize: 'none', // Prevents resizing
                                                        overflow: 'auto',
                                                        color: '#B2EBF2', // Text color
                                                        backgroundColor: 'transparent', // Set a lighter background color
                                                    }}
                                                />
                                            </div>
                                        </div> 
                                        {/* Save */}
                                        <Box
                                            gridColumn="span 12"
                                            // backgroundColor={colors.primary[400]}
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Box sx={{ textAlign: 'center' }}>
                                                <Button 
                                                variant="contained"
                                                sx={{
                                                    color: '#000000', // Text color
                                                    backgroundColor: '#FFD700', // Background color
                                                    '&:hover': {
                                                    backgroundColor: '#FFD700', // Background color on hover
                                                    },
                                                }}
                                                onClick={handleSubmitProjectProgress}
                                                >
                                                    Save
                                                </Button>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>                                
                            </Collapse>
                        </div>
                        <Box sx={{ width: '100%', typography: 'body1' }}>
                            <Box>
                                <Table aria-labelledby="tableTitle"> 
                                    <TableHead style={{backgroudColor:'black'}}>
                                        <TableRow>
                                            <TableCell style={{ color: '#B2EBF2' }}>Status</TableCell>
                                            <TableCell style={{ color: '#B2EBF2' }}>Week</TableCell>
                                            <TableCell style={{ color: '#B2EBF2' }}>Percent grogress</TableCell>
                                            <TableCell style={{ color: '#B2EBF2' }}>Progress update</TableCell>
                                            <TableCell style={{ color: '#B2EBF2' }}>Next step</TableCell>
                                            <TableCell style={{ color: '#B2EBF2' }}>Problem</TableCell>
                                            <TableCell style={{ color: '#B2EBF2' }}>Created date</TableCell>
                                            <TableCell style={{ color: '#B2EBF2' }}>Manage</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {allProgress !== undefined ? allProgress
                                        .map((progress) => (
                                        <TableRow>
                                            <TableCell style={{ color: '#B2EBF2', fontSize: '18px', }}>{progress.status}</TableCell>
                                            <TableCell style={{ color: '#B2EBF2', fontSize: '18px', }}>{progress.week}</TableCell>
                                            <TableCell style={{ color: '#B2EBF2', fontSize: '18px', }}>{progress.percent_progress}</TableCell>
                                            <TableCell style={{ color: '#B2EBF2' }}>{progress.progress_update}</TableCell>
                                            <TableCell style={{ color: '#B2EBF2', fontSize: '18px', }}>{progress.next_step}</TableCell>
                                            <TableCell style={{ color: '#B2EBF2', fontSize: '18px', }}>{progress.problem}</TableCell>
                                            <TableCell style={{ color: '#B2EBF2', fontSize: '18px', }}>{progress.created_at}</TableCell>
                                            <TableCell style={{ color: '#B2EBF2', fontSize: '18px', }}>
                                                <Button
                                                onClick={(e) => handleDeleteProgress(progress.created_at)}
                                                variant="contained"
                                                color="error"
                                                >
                                                    Delete
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                        )): ''}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Box>
                    </TabPanel>
                </TabContext>
            </Box>
        </Item>
        
    </Box>
  )
}

export default FormCreateEdit
