import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";
import './../src/fonts/fonts.css'; // Import the CSS file
// Color design tokens export
export const tokens = (mode) => ({
  ...(mode === "dark"
    ? {
        primary: {
          100: "#e1e2fe",
          200: "#c3c6fd",
          300: "#a4a9fc",
          400: "#868dfb",
          500: "#302b63", // medium blue
          600: "#535ac8",
          700: "#3e4396",
          800: "#2a2d64",
          900: "#151632",
        },
        secondary: {
          100: "#dbf5ee",
          200: "#b7ebde",
          300: "#94e2cd",
          400: "#70d8bd",
          500: "#24243e", // teal
          600: "#3da58a",
          700: "#2e7c67",
          800: "#1e5245",
          900: "#0f2922",
        },
        accent: {
          100: "#e0e0e0",
          200: "#c2c2c2",
          300: "#a3a3a3",
          400: "#858585",
          500: "#0f0c29", // dark navy blue
          600: "#525252",
          700: "#3d3d3d",
          800: "#292929",
          900: "#141414",
        },
      }
    : {
        primary: {
          100: "#151632",
          200: "#2a2d64",
          300: "#3e4396",
          400: "#535ac8",
          500: "#6870fa",
          600: "#868dfb",
          700: "#a4a9fc",
          800: "#c3c6fd",
          900: "#e1e2fe",
        },
        secondary: {
          100: "#0f2922",
          200: "#1e5245",
          300: "#2e7c67",
          400: "#3da58a",
          500: "#4cceac",
          600: "#70d8bd",
          700: "#94e2cd",
          800: "#b7ebde",
          900: "#dbf5ee",
        },
        accent: {
          100: "#141414",
          200: "#292929",
          300: "#3d3d3d",
          400: "#525252",
          500: "#666666",
          600: "#858585",
          700: "#a3a3a3",
          800: "#c2c2c2",
          900: "#e0e0e0",
        },
      }),
});

// MUI theme settings
export const themeSettings = (mode) => {
  const colors = tokens(mode);
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            // Palette values for dark mode
            primary: {
              main: colors.primary[500],
            },
            secondary: {
              main: colors.secondary[500],
            },
            accent: {
              main: colors.accent[500],
            },
            background: {
              default: colors.primary[800],
              paper: colors.primary[700],
            },
          }
        : {
            // Palette values for light mode
            primary: {
              main: colors.primary[100],
            },
            secondary: {
              main: colors.secondary[500],
            },
            accent: {
              main: colors.accent[500],
            },
            background: {
              default: "#fcfcfc",
              paper: colors.primary[100],
            },
          }),
    },
    typography: {
      fontFamily: ["CPF Imm Sook"].join(","),
      fontSize: 18,
      h1: {
        fontFamily: ["CPF Imm Sook"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["CPF Imm Sook"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["CPF Imm Sook"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["CPF Imm Sook"].join(","),
        fontSize: 22,
      },
      h5: {
        fontFamily: ["CPF Imm Sook"].join(","),
        fontSize: 20,
      },
      h6: {
        fontFamily: ["CPF Imm Sook"].join(","),
        fontSize: 18,
      },
    },
  };
};

// Context for color mode
export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

export const useMode = () => {
  const [mode, setMode] = useState("dark");

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () =>
        setMode((prev) => (prev === "light" ? "dark" : "light")),
    }),
    []
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  return [theme, colorMode];
};
