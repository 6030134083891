import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import Chart from 'react-apexcharts'

function ColumnChart(props) {
  
    const options = {
        chart: {
          type: 'bar',
          height: 350,
          toolbar: {
            show: false // Hide the toolbar
          },
          backgroundColor: 'transparent',
          foreColor: '#B2EBF2', // Default text color
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false,
          position: 'top'
        },
        xaxis: {
          categories: props.data.label,
        },
        yaxis: {
          show: false, // This will hide the Y-axis labels
        },
        grid: {
          show: false, // This will hide the grid lines
        },
        // title: {
        //   text: 'Monthly Data',
        //   align: 'center', // Aligns the title in the center
        //   margin: 10, // Adds some margin to the title for spacing
        //   style: {
        //     fontSize: '24px',
        //     color: '#263238'
        //   }
        // },
        colors: props.colors,
        fill: {
          opacity: 1
        },
        tooltip: {
          theme: 'dark', // Dark theme for tooltip
          style: {
            fontSize: '14px',
            fontFamily: 'CPF Imm Sook',
            color: '#FFFFFF' // Tooltip text color
          },
        },
    };
      
    const series = [{
    name: 'Saving(MB)',
    data: props.data.series
    }];
  return (
        <Box sx={{ width: '100%', display: 'block', justifyContent: 'center' }}>
            <Chart options={options} series={series} type="bar" height={props.height} width='100%' />
        </Box>
  )
}

ColumnChart.propTypes = {

}

export default ColumnChart

