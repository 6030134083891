import { useState } from 'react'
import { Link } from 'react-router-dom'
import mainLogo from '../../assets/images/PMS.png'
import HomeIcon from '@mui/icons-material/Home'
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import ContrastOutlinedIcon from '@mui/icons-material/ContrastOutlined';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { tokens } from "../../theme";


const drawerWidth = 200;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end'
}));


const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const mainSidebarItems = [
    { text: 'Home', icon: <HomeIcon />, link: '/' },
    { text: 'Form', icon: <InsertChartOutlinedIcon />, link: '/project-summary' },
    { text: 'Report', icon: <EditNoteOutlinedIcon />, link: '/report' },
    // { text: 'Settings', icon: <SettingsOutlinedIcon />, link: '/settings' },
  ];
  const handleLogout = () => {
    localStorage.clear();
    window.location = "/login";
  };

  return (
    <Box sx={{ display: 'flex', backgroundColor: 'transparent' }}>
      <CssBaseline />
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: 'transparent',
            color: '#FFFFFF', // Ensures text color is white for contrast
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader sx={{ background: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
          <IconButton>
            <img src={mainLogo} width={60} height={60} alt="Logo" />
          </IconButton>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: 'white' }}>
            PMS
          </Typography>
        </DrawerHeader>
        <Divider sx={{ backgroundColor: 'transparent' }} />
        <List sx={{ backgroundColor: 'transparent' }}>
          {mainSidebarItems.map((item, index) => (
            <ListItem key={item.text} disablePadding>
              <ListItemButton component={Link} to={item.link}>
                <ListItemIcon sx={{ color: '#FFFFFF' }}>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} sx={{ color: '#FFFFFF', fontSize: '18px' }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider sx={{ backgroundColor: '#8888AA' }} />
        {/* <List sx={{ backgroundColor: 'transparent' }}>
          {['Theme', 'Log Out'].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton>
                <ListItemIcon sx={{ color: '#FFFFFF' }}>
                  {index % 2 === 0 ? <ContrastOutlinedIcon /> : <LogoutOutlinedIcon />}
                </ListItemIcon>
                <ListItemText primary={text} sx={{ color: '#FFFFFF' }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List> */}
        <List sx={{ backgroundColor: 'transparent' }}>
        {['Log Out'].map((text) => (
          <ListItem key={text} disablePadding>
            <ListItemButton onClick={handleLogout}>
              <ListItemIcon sx={{ color: '#FFFFFF' }}>
                <LogoutOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={text} sx={{ color: '#FFFFFF' }} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      </Drawer>
    </Box>
  );
};

export default Sidebar;