import { Box, Button, Checkbox, Tab, TextField, Grid, Paper, styled, Typography, InputLabel } from '@mui/material'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { useState, useEffect} from 'react'
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { tokens } from "../../theme";
import '../../index.css'
import Swal from 'sweetalert2';
import { apiUrl } from '../../constants'
import axios from 'axios'
import SearchBar from '../../components/SearchBar'
import withReactContent from 'sweetalert2-react-content';
import { useLocation } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import Slider from '@mui/material/Slider';
import CircularProgress from '@mui/material/CircularProgress';

import ColumnChart from '../../components/ColumnChart'
import DonutChart from '../../components/DonutChart'
import StackedBarChartTeamStatus from '../../components/StackedBarChartTeamStatus'
import MultiLineGraph from '../../components/MultiLineGraph'
import BarChart from '../../components/BarChart'
import StackedBarChartTeamAveragePercent from '../../components/StackedBarChartTeamAveragePercent';

const gradientBackground = 'linear-gradient(to right, #0f0c29, #302b63, #24243e)'; 
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: 'transparent',
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginLeft: '2%',
    marginRight: '2%',
    marginBottom: '2%',
    maxHeight: '90vh', 
    overflowY: 'auto',

    [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginLeft: 0,
        marginRight: 0,
        marginBottom: '10px',
        height: 'auto',
    },
}));

const Report = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [value, setValue] = useState("1")
    const year = new Date().getFullYear();
    const [buDonut, setBUDonut] = useState([]);
    const [buColumn, setBUColumn] = useState([]);
    const [buTable, setBUTable] = useState([]);
    const [teamDonut, setTeamDonut] = useState([]);
    const [teamColumn, setTeamColumn] = useState([]);
    const [teamTable, setTeamTable] = useState([]);
    const [projectStatusDonut, setProjectStatusDonut] = useState([]);
    const [teamStatusBarChart, setTeamStatusBarChart] = useState([]);
    const [projectPercentRangeStatusDonut, setProjectPercentRangeStatusDonut] = useState([]);
    const [teamAverageStatusBarChart, setTeamAverageStatusBarChart] = useState([]);
    const [costSavingsLineChart, setCostSavingsLineChart] = useState([]);
    const [teamCostSavingBarChart, setTeamCostSavingBarChart] = useState([]);
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    // get bu donut
    useEffect(() => {
        checkToken()
        var url = `${apiUrl.BU_PROJECT_DONUT_URL}`
        let data = {'year': year}
        axios.post(url, data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("rb_ac_token_pms")}`,                
                }
            }
        )
        .then(response => {
            if (response.status === 200) {
                setBUDonut(response.data)
            }
        })
        .catch(error => {
            // console.log('error: ', error)
            
        })
    }, []);

    // get bu column
    useEffect(() => {
        checkToken()
        var url = `${apiUrl.BU_SAVING_COLUMN_URL}`
        let data = {'year': year}
        axios.post(url, data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("rb_ac_token_pms")}`,                
                }
            }
        )
        .then(response => {
            if (response.status === 200 && response.data) {
                const maxLength = 8; // Adjust the maxLength as needed
                if (Array.isArray(response.data.series) && Array.isArray(response.data.label)) {
                    const processedData = {
                      series: response.data.series,
                      label: splitLabelsBUs(response.data.label, maxLength)
                    };
                    setBUColumn(processedData);
                  } else {
                    console.error('Invalid data format:', response.data);
                  }
            }
        })
        .catch(error => {
            // console.log('error: ', error)
            
        })
    }, []);

    // get bu table
    useEffect(() => {
        checkToken()
        var url = `${apiUrl.BU_SUMMARY_TABLE_URL}`
        let data = {'year': year}
        axios.post(url, data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("rb_ac_token_pms")}`,                
                }
            }
        )
        .then(response => {
            if (response.status === 200) {
                setBUTable(response.data)
            }
        })
        .catch(error => {
            // console.log('error: ', error)
            
        })
    }, []);

    // get team donut
    useEffect(() => {
        checkToken()
        var url = `${apiUrl.TEAM_PROJECT_DONUT_URL}`
        let data = {'year': year}
        axios.post(url, data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("rb_ac_token_pms")}`,                
                }
            }
        )
        .then(response => {
            if (response.status === 200) {
                setTeamDonut(response.data)
            }
        })
        .catch(error => {
            // console.log('error: ', error)
            
        })
    }, []);

    // get team column
    useEffect(() => {
        checkToken()
        var url = `${apiUrl.TEAM_SAVING_COLUMN_URL}`
        let data = {'year': year}
        axios.post(url, data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("rb_ac_token_pms")}`,                
                }
            }
        )
        .then(response => {
            if (response.status === 200 && response.data) {
                const maxLength = 8; // Adjust the maxLength as needed
                if (Array.isArray(response.data.series) && Array.isArray(response.data.label)) {
                    const processedData = {
                      series: response.data.series,
                      label: splitLabelsTeams(response.data.label, maxLength)
                    };
                    setTeamColumn(processedData);
                  } else {
                    console.error('Invalid data format:', response.data);
                  }
            }
        })
        .catch(error => {
            // console.log('error: ', error)
            
        })
    }, []);

    // get team table
    useEffect(() => {
        checkToken()
        var url = `${apiUrl.TEAM_SUMMARY_TABLE_URL}`
        let data = {'year': year}
        axios.post(url, data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("rb_ac_token_pms")}`,                
                }
            }
        )
        .then(response => {
            if (response.status === 200) {
                appendTotals(response.data)
                setTeamTable(response.data)
                
            }
        })
        .catch(error => {
            // console.log('error: ', error)
            
        })
    }, []);

    // get status projects donut
    useEffect(() => {
        checkToken()
        var url = `${apiUrl.PROJECTS_STATUS_URL}`
        let data = {'year': year}
        axios.post(url, data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("rb_ac_token_pms")}`,                
                }
            }
        )
        .then(response => {
            if (response.status === 200) {
                setProjectStatusDonut(response.data)
            }
        })
        .catch(error => {
            // console.log('error: ', error)
            
        })
    }, []);

    // get status teams barchart
    useEffect(() => {
        checkToken()
        var url = `${apiUrl.TEAMS_STATUS_URL}`
        let data = {'year': year}
        axios.post(url, data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("rb_ac_token_pms")}`,                
                }
            }
        )
        .then(response => {
            if (response.status === 200) {
                setTeamStatusBarChart(response.data)
            }
        })
        .catch(error => {
            // console.log('error: ', error)
            
        })
    }, []);

    // get status percent range projects donut
    useEffect(() => {
        checkToken()
        var url = `${apiUrl.PROJECTS_PERCENT_RANGE_STATUS_URL}`
        let data = {'year': year}
        axios.post(url, data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("rb_ac_token_pms")}`,                
                }
            }
        )
        .then(response => {
            if (response.status === 200) {
                setProjectPercentRangeStatusDonut(response.data)
            }
        })
        .catch(error => {
            // console.log('error: ', error)
            
        })
    }, []);

    // get status teams average barchart
    useEffect(() => {
        checkToken()
        var url = `${apiUrl.TEAMS_PERCENT_RANGE_STATUS_URL}`
        let data = {'year': year}
        axios.post(url, data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("rb_ac_token_pms")}`,                
                }
            }
        )
        .then(response => {
            if (response.status === 200) {
                setTeamAverageStatusBarChart(response.data)
            }
        })
        .catch(error => {
            // console.log('error: ', error)
            
        })
    }, []);

    // get cost saving multi line chart
    useEffect(() => {
        checkToken()
        var url = `${apiUrl.COST_SAVINGS_URL}`
        let data = {'year': year}
        axios.post(url, data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("rb_ac_token_pms")}`,                
                }
            }
        )
        .then(response => {
            if (response.status === 200) {
                setCostSavingsLineChart(fillMissingMonths(response.data, year))
            }
        })
        .catch(error => {
            // console.log('error: ', error)
            
        })
    }, []);

    // get team cost saving multi line chart
    useEffect(() => {
        checkToken()
        var url = `${apiUrl.TEAM_COST_SAVINGS_URL}`
        let data = {'year': year}
        axios.post(url, data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("rb_ac_token_pms")}`,                
                }
            }
        )
        .then(response => {
            if (response.status === 200) {
                setTeamCostSavingBarChart(response.data);
            }
        })
        .catch(error => {
            // console.log('error: ', error)
            
        })
    }, []);

    // check token
    const checkToken =  async() => {
        let access_exp = localStorage.getItem("rb_ac_exp_pms");
        let refresh_exp = localStorage.getItem("rb_rf_exp_pms");
        if (refresh_exp < Date.now() / 1000) {
            console.log("refresh is expiried");
            // alert('Please log in again')
            localStorage.clear();
            window.location = "/login";
        } else if (access_exp < Date.now() / 1000) {
            const refresh = localStorage.getItem("rb_rf_token_pms");
            
            let data = {
                refresh_token: refresh,
            };
            await axios.post(`${apiUrl.REFRESH_TOKEN_URL}`, data).then(response => {
                // console.log("got new access_token!");
                localStorage.setItem("rb_ac_token_pms", response.data.accessToken);
            })
        
        }
    };

    const fillMissingMonths = (data, year) => {
        const allMonths = Array.from({ length: 12 }, (_, i) => {
            const month = (i + 1).toString().padStart(2, '0');
            return `${year}-${month}`;
        });
    
        const existingMonths = new Set(data.label);
        const missingMonths = allMonths.filter(month => !existingMonths.has(month));
    
        missingMonths.forEach(month => {
            data.label.push(month);
            data.target.push(0);
            data.actual.push(0);
        });
    
        // Sort by month
        const sortedIndices = data.label.map((month, index) => ({ month, index }))
            .sort((a, b) => a.month.localeCompare(b.month))
            .map(item => item.index);
    
        data.label = sortedIndices.map(index => data.label[index]);
        data.target = sortedIndices.map(index => data.target[index]);
        data.actual = sortedIndices.map(index => data.actual[index]);
    
        // Recalculate running totals
        data.running_target = data.target.reduce((acc, value, index) => {
            acc.push((acc[index - 1] || 0) + value);
            return acc;
        }, []);
    
        data.running_actual = data.actual.reduce((acc, value, index) => {
            acc.push((acc[index - 1] || 0) + value);
            return acc;
        }, []);
    
        // Convert labels to short month names
        data.label = data.label.map(label => {
            const [year, month] = label.split('-');
            return monthNames[parseInt(month, 10) - 1];
        });
        return data;
    };

    function appendTotals(data) {
        Object.keys(data).forEach(key => {
            const values = data[key];
            if (Array.isArray(values)) {
                const total = values.reduce((sum, value) => {
                    if (value === null || value === undefined) value = 0;
                    return sum + parseFloat(value);
                }, 0);
                if (key !== "team") {
                    values.push(total.toFixed(2));
                }
                else {
                    values.push('Total');
                }
                
            }
        });
    }
    
    const splitLabelsTeams = (labels, maxLength) => {
        return labels.map(label => {
          if (!label) return label; // Handle undefined or null labels
          let currentLine = ['วิศวกรรม', 'เพื่อ', 'ความยั่งยืน'];
            if (label === 'Strategy & Engineering Management'){
                currentLine = ['Strategy &', 'Engineering', 'Management']
            }
            if (label=== 'ความปลอดภัย อาชีวอนามัย สิ่งแวดล้อมและพลังงาน'){
                currentLine = ['ความปลอดภัย', 'อาชีวอนามัย' ,'สิ่งแวดล้อม', 'และพลังงาน']
            }
            if (label === 'พัฒนาประสิทธิภาพและนวัตกรรม'){
                currentLine = ['พัฒนา', 'ประสิทธิภาพ' ,' และนวัตกรรม']
            }
            if (label === 'วิศวกรรมก่อสร้าง'){
                currentLine = ['วิศวกรรม', 'ก่อสร้าง']
            }
            if (label === 'วิศวกรรมเกษตร'){
                currentLine = ['วิศวกรรม', 'เกษตร']
            }
            if (label === 'วิศวกรรมแปรรูปเนื้อสัตว์'){
                currentLine = ['วิศวกรรม', 'แปรรูปเนื้อสัตว์']
            }
            if (label === 'วิศวกรรมพื้นฐานและงานระบบ'){
                currentLine = ['วิศวกรรม', 'พื้นฐาน', 'และ', 'งานระบบ']
            }
            if (labels === 'วิศวกรรมเพื่อความยั่งยืน'){
                currentLine = ['วิศวกรรม', 'เพื่อ', 'ความ', 'ยั่งยืน']
            }
            if (label === 'วิศวกรรมสัตว์น้ำ'){
                currentLine = ['วิศวกรรม', 'สัตว์น้ำ']
            }
            if (label === 'วิศวกรรมสัตว์บก'){
                currentLine = ['วิศวกรรม', 'สัตว์บก']
            }
            if (label === 'วิศวกรรมอาหารสำเร็จรูป'){
                currentLine = ['วิศวกรรม', 'อาหารสำเร็จรูป']
            }
        
            return currentLine;
        });
      };
    
      const splitLabelsBUs = (labels, maxLength) => {
        return labels.map(label => {
          if (!label) return label; // Handle undefined or null labels
          let currentLine = 'อื่นๆ';
            if (label === 'Cross BU'){
                currentLine = ['Cross BU']
            }
            if (label=== 'ธุรกิจไก่เนื้อและเป็ดเนื้อ'){
                currentLine = ['ธุรกิจไก่เนื้อ', 'และ', 'เป็ดเนื้อ']
            }
            if (label === 'ธุรกิจในต่างประเทศ'){
                currentLine = ['ธุรกิจใน', 'ต่างประเทศ']
            }
            if (label === 'ธุรกิจสัตว์น้ำ'){
                currentLine = ['ธุรกิจ', 'สัตว์น้ำ']
            }
            if (label === 'ธุรกิจสุกร'){
                currentLine = ['ธุรกิจสุกร']
            }
            if (label === 'ธุรกิจอาหารสัตว์บก'){
                currentLine = ['ธุรกิจอาหาร', 'สัตว์บก']
            }
            if (label === 'ธุรกิจอาหารสำเร็จรูป'){
                currentLine = ['ธุรกิจอาหาร', 'สำเร็จรูป']
            }
            if (labels === 'อื่นๆ'){
                currentLine = ['อื่นๆ']
            }
            return currentLine;
        });
      };

  return (
    <Box sx={{ padding: '2px', backgroundColor: 'transparent',}}>
        <Item>
            <Box sx={{ width: '100%', typography: 'body1', backgroundColor: 'transparent', }}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                        sx={{
                            '& .MuiTab-root': {
                            color: '#D3D3D3', // Light gray text color
                            backgroundColor: '#008282', // Teal background color
                            '&.Mui-selected': {
                                backgroundColor: '#006868', // Darker teal background color for selected tab
                                color: '#FFD700', // Light yellow text color for selected tab
                            },
                            },
                            '& .MuiTabs-indicator': {
                            backgroundColor: '#FFD700', // Gold-like color for the indicator (can be adjusted to match the teal palette)
                            },
                        }}
                        >
                            <Tab label="Project Saving" value="1" />
                            <Tab label="Project Status" value="2" />
                            <Tab label="Project Saving by BU" value="3" />
                            <Tab label="Cost Saving" value="4" />
                        </TabList>
                    </Box>


                    {/* TAB 1 */}
                    <TabPanel value="1" sx={{ minHeight: '70vh', backgroundColor: 'transparent' }}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12}>
                                {/* TITLE */}
                                <Box
                                    gridColumn="span 12"
                                    backgroundColor='rgba(255, 255, 255, 0.2)'
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Typography
                                        variant="h4"
                                        fontWeight="600"
                                        color='#FFD700'
                                    >
                                        Total project
                                    </Typography>
                                </Box>
                                {/* CHART */}
                                {
                                    teamDonut.length !== 0?
                                    <DonutChart 
                                    data={teamDonut} 
                                    height='300%' 
                                    legend_position='bottom' 
                                    mode='total'
                                    colors={[
                                        '#008080',
                                        '#00CED1',
                                        '#87CEEB',
                                        '#90EE90',
                                        '#FFA500',
                                        '#9370DB',
                                        '#FF69B4',
                                        '#FFD700',
                                        '#D3D3D3',
                                        '#FFFFFF',
                                        '#FF7F50',
                                        '#F08080',
                                        '#87CEFA',
                                        '#9370DB',
                                        '#FFD700'
                                        ]}
                                    /> :
                                    <Typography
                                        variant="h4"
                                        fontWeight="600"
                                        color='#B2EBF2'
                                    >
                                        No data
                                    </Typography>
                                }
                                
                            </Grid>
                            <Grid item xs={12} sm={12}>

                                {/* TITLE */}
                                <Box
                                    gridColumn="span 12"
                                    backgroundColor='rgba(255, 255, 255, 0.2)'
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Typography
                                        variant="h4"
                                        fontWeight="600"
                                        color='#FFD700'
                                    >
                                        Estimated saving by team
                                    </Typography>
                                </Box>
                                
                                
                                {/* CHART */}
                                <Box
                                    gridColumn="span 12"
                                    // backgroundColor={colors.primary[400]}
                                    display="block"
                                    alignItems="center"
                                    justifyContent="center"
                                    sx={{width: '100%'}}
                                >
                                    {
                                    teamColumn.length !== 0?
                                    <ColumnChart data={teamColumn} height='400' colors="#66C2C2"/> :
                                    <Typography
                                        variant="h4"
                                        fontWeight="600"
                                        color='#B2EBF2'
                                    >
                                        No data
                                    </Typography>
                                    }
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                {/* TABLE */}
                                <Box
                                    // mt='-7%'
                                    gridColumn="span 12"
                                    // backgroundColor={colors.primary[400]}
                                    display="block"
                                    alignItems="center"
                                    justifyContent="center"
                                    sx={{width: '100%'}}
                                >
                                    {
                                    teamTable.length !== 0?
                                    <TableContainer component={Paper} sx={{ overflowX: 'auto', width: '100%', backgroundColor: '#1C1B29' }}>
                                        <Table sx={{ width: '100%' }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow sx={{ backgroundColor: '#2E3B4E'}}>
                                                    <TableCell sx={{ fontWeight: 'bold', padding: '8px', width: '100px', wordWrap: 'break-word', textAlign: 'center', verticalAlign: 'middle', color: '#B2EBF2' }}>Team</TableCell>
                                                    {teamTable.team.map((item, index) => (
                                                        <TableCell key={index} sx={{ padding: '8px', width: '100px', textAlign: 'center', verticalAlign: 'middle', color: '#B2EBF2' }}>
                                                            {item}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow sx={{ backgroundColor: '#1C1B29', borderTop: '2px solid #FFD700'  }}>
                                                    <TableCell sx={{ fontWeight: 'bold', padding: '8px', width: '100px', wordWrap: 'break-word', color: '#FFD700' }}>Head count</TableCell>
                                                        {teamTable.head_count.map((item, index) => (
                                                    <TableCell key={index} sx={{ padding: '8px', width: '100px', textAlign: 'center', wordWrap: 'break-word', color: '#B2EBF2' }}>{item !== null?parseFloat(item).toFixed(0): 'N/A'}</TableCell>
                                                    ))}
                                                </TableRow>
                                                <TableRow sx={{ backgroundColor: '#1C1B29' }}>
                                                    <TableCell sx={{ fontWeight: 'bold', padding: '8px', width: '100px', wordWrap: 'break-word', color: '#FFD700' }}>Target saving</TableCell>
                                                    {teamTable.target_saving.map((item, index) => (
                                                    <TableCell key={index} sx={{ padding: '8px', width: '100px', textAlign: 'center', wordWrap: 'break-word', color: '#B2EBF2' }}>{item !== null?parseFloat(item).toFixed(0): 'N/A'}</TableCell>
                                                    ))}
                                                </TableRow>
                                                <TableRow sx={{ backgroundColor: '#1C1B29' }}>
                                                    <TableCell sx={{ fontWeight: 'bold', padding: '8px', width: '100px', wordWrap: 'break-word', color: '#FFD700' }}>Estimated saving</TableCell>
                                                    {teamTable.estimated_saving.map((item, index) => (
                                                    <TableCell key={index} sx={{ padding: '8px', width: '100px', textAlign: 'center', wordWrap: 'break-word', color: '#B2EBF2' }}>{item !== null?parseFloat(item).toFixed(1): 'N/A'}</TableCell>
                                                    ))}
                                                </TableRow>
                                                <TableRow sx={{ backgroundColor: '#1C1B29', borderBottom: '2px solid #FFD700' }}>
                                                    <TableCell sx={{ fontWeight: 'bold', padding: '8px', width: '100px', wordWrap: 'break-word', color: '#FFD700' }}>Actual saving</TableCell>
                                                    {teamTable.actual_saving.map((item, index) => (
                                                    <TableCell key={index} sx={{ padding: '8px', width: '100px', textAlign: 'center', wordWrap: 'break-word', color: '#B2EBF2' }}>{item !== null?parseFloat(item).toFixed(1): 'N/A'}</TableCell>
                                                    ))}
                                                </TableRow>
                                                <TableRow sx={{ backgroundColor: '#1C1B29' }}>
                                                    <TableCell sx={{ fontWeight: 'bold', padding: '8px', width: '100px', wordWrap: 'break-word', color: '#FFD700' }}>Estimated saving/head count</TableCell>
                                                    {teamTable.estimated_saving_per_head.map((item, index) => (
                                                    <TableCell key={index} sx={{ padding: '8px', width: '100px', textAlign: 'center', wordWrap: 'break-word', color: '#B2EBF2' }}>{item !== null?parseFloat(item).toFixed(1): 'N/A'}</TableCell>
                                                    ))}
                                                </TableRow>
                                                <TableRow sx={{ backgroundColor: '#1C1B29', borderBottom: '2px solid #FFD700' }}>
                                                    <TableCell sx={{ fontWeight: 'bold', padding: '8px', width: '100px', wordWrap: 'break-word', color: '#FFD700' }}>Actual saving/head count</TableCell>
                                                    {teamTable.actual_saving_per_head.map((item, index) => (
                                                    <TableCell key={index} sx={{ padding: '8px', width: '100px', textAlign: 'center', wordWrap: 'break-word', color: '#B2EBF2' }}>{item !== null?parseFloat(item).toFixed(2): 'N/A'}</TableCell>
                                                    ))}
                                                </TableRow>
                                                <TableRow sx={{ backgroundColor: '#1C1B29'}}>
                                                    <TableCell sx={{ fontWeight: 'bold', padding: '8px', width: '100px', wordWrap: 'break-word', color: '#FFD700' }}>No. of project</TableCell>
                                                    {teamTable.project_count.map((item, index) => (
                                                    <TableCell key={index} sx={{ padding: '8px', width: '100px', textAlign: 'center', wordWrap: 'break-word', color: '#B2EBF2' }}>{item !== null?parseFloat(item).toFixed(0): 'N/A'}</TableCell>
                                                    ))}
                                                </TableRow>
                                                <TableRow sx={{ backgroundColor: '#1C1B29', borderBottom: '2px solid #FFD700' }}>
                                                    <TableCell sx={{ fontWeight: 'bold', padding: '8px', width: '100px', wordWrap: 'break-word', color: '#FFD700' }}>No. of project/head count</TableCell>
                                                    {teamTable.project_count_per_head.map((item, index) => (
                                                    <TableCell key={index} sx={{ padding: '8px', width: '100px', textAlign: 'center', wordWrap: 'break-word', color: '#B2EBF2' }}>{item !== null?parseFloat(item).toFixed(0): 'N/A'}</TableCell>
                                                    ))}
                                                </TableRow>
                                                
                                                <TableRow sx={{ backgroundColor: '#1C1B29' }}>
                                                    <TableCell sx={{ fontWeight: 'bold', padding: '8px', width: '100px', wordWrap: 'break-word', color: '#FFD700' }}>Estimated saving/project</TableCell>
                                                    {teamTable.estimated_saving_per_project.map((item, index) => (
                                                    <TableCell key={index} sx={{ padding: '8px', width: '100px', textAlign: 'center', wordWrap: 'break-word', color: '#B2EBF2' }}>{item !== null?parseFloat(item).toFixed(1): 'N/A'}</TableCell>
                                                    ))}
                                                </TableRow>
                                                <TableRow sx={{ backgroundColor: '#1C1B29' }}>
                                                    <TableCell sx={{ fontWeight: 'bold', padding: '8px', width: '100px', wordWrap: 'break-word', color: '#FFD700' }}>Actual saving/project</TableCell>
                                                    {teamTable.actual_saving_per_project.map((item, index) => (
                                                    <TableCell key={index} sx={{ padding: '8px', width: '100px', textAlign: 'center', wordWrap: 'break-word', color: '#B2EBF2' }}>{item !== null?parseFloat(item).toFixed(1): 'N/A'}</TableCell>
                                                    ))}
                                                </TableRow>
                                                
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    :
                                    <Typography
                                        variant="h4"
                                        fontWeight="600"
                                        color='#B2EBF2'
                                    >
                                        No data
                                    </Typography>
                                    }
                                </Box>
                            </Grid>
                        </Grid>   
                    </TabPanel>
                    
                    {/* TAB 2 */}
                    <TabPanel value="2" sx={{ minHeight: '70vh', backgroundColor: 'transparent' }}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                 {/* TITLE */}
                                 <Box
                                    gridColumn="span 12"
                                    backgroundColor='rgba(255, 255, 255, 0.2)'
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Typography
                                        variant="h4"
                                        fontWeight="600"
                                        color='#FFD700'
                                    >
                                        {projectStatusDonut.length !== 0?
                                        <div>
                                            Overview project status
                                        </div>
                                        :
                                        ''
                                        }
                                    </Typography>
                                </Box>
                                {/* CHART */}
                                
                                {
                                    projectStatusDonut.length !== 0?
                                    <DonutChart 
                                    data={projectStatusDonut}
                                    height='300%'
                                    legend_position='bottom'
                                    mode='total'
                                    colors={['#D3D3D3', '#90EE90', '#FFD700','#FF6347', '#FFA500', '#00CED1']}
                                    /> 
                                    :
                                    <Typography
                                        variant="h4"
                                        fontWeight="600"
                                        color='#B2EBF2'
                                    >
                                        No data
                                    </Typography>
                                }
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography
                                    variant="h4"
                                    fontWeight="600"
                                    color='#FFD700'
                                >
                                    Project status by team
                                </Typography>
                                {
                                    teamStatusBarChart.length !== 0?
                                    <StackedBarChartTeamStatus 
                                    data={teamStatusBarChart}
                                    colors={['#D3D3D3', '#90EE90', '#FFD700','#FF6347', '#FFA500', '#00CED1']}
                                    />
                                    :
                                    <Typography
                                        variant="h4"
                                        fontWeight="600"
                                        color='#B2EBF2'
                                    >
                                        No data
                                    </Typography>
                                }
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {/* TITLE */}
                                <Box
                                    gridColumn="span 12"
                                    backgroundColor='rgba(255, 255, 255, 0.2)'
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Typography
                                        variant="h4"
                                        fontWeight="600"
                                        color='#FFD700'
                                    >
                                        {projectPercentRangeStatusDonut.length !== 0?
                                        <div>
                                            Overview project progress
                                        </div>
                                        :
                                        ''
                                        }
                                    </Typography>
                                </Box>
                                {/* CHART */}
                                {
                                    projectPercentRangeStatusDonut.length !== 0?
                                    <DonutChart 
                                    data={projectPercentRangeStatusDonut} 
                                    height='250%' 
                                    legend_position='bottom' 
                                    mode='average'
                                    colors={['#90EE90', '#3CB371', '#2E8B57', '#556B2F']}
                                    /> :
                                    <Typography
                                        variant="h4"
                                        fontWeight="600"
                                        color='#B2EBF2'
                                    >
                                        No data
                                    </Typography>
                                }
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography
                                    variant="h4"
                                    fontWeight="600"
                                    color='#FFD700'
                                >
                                    Project progress by team
                                </Typography>
                                {
                                    teamAverageStatusBarChart.length !== 0?
                                    <StackedBarChartTeamAveragePercent data={teamAverageStatusBarChart} colors="#66C2C2"/>
                                    :
                                    <Typography
                                        variant="h4"
                                        fontWeight="600"
                                        color='#B2EBF2'
                                    >
                                        No data
                                    </Typography>
                                }
                            </Grid>

                        </Grid>
                        
                    </TabPanel>

                    {/* TAB 3 */}
                    
                    <TabPanel value="3" sx={{ minHeight: '80vh', backgroundColor: 'transparent' }}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={4}>
                                {/* TITLE */}
                                <Box
                                    gridColumn="span 12"
                                    backgroundColor='rgba(255, 255, 255, 0.2)'
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Typography
                                        variant="h4"
                                        fontWeight="600"
                                        color='#FFD700'
                                    >
                                        Total project
                                    </Typography>
                                </Box>
                                {/* CHART */}
                                {
                                    buDonut.length !== 0?
                                    <DonutChart
                                    data={buDonut} 
                                    height='300%' 
                                    legend_position='bottom' 
                                    mode='total'
                                    colors={[
                                        '#008080',
                                        '#00CED1',
                                        '#87CEEB',
                                        '#90EE90',
                                        '#FFA500',
                                        '#9370DB',
                                        '#FF69B4',
                                        '#FFD700',
                                        '#D3D3D3',
                                        '#FFFFFF',
                                        '#FF7F50',
                                        '#F08080',
                                        '#87CEFA',
                                        '#9370DB',
                                        '#FFD700'
                                    ]}
                                    /> :
                                    <Typography
                                    variant="h4"
                                    fontWeight="600"
                                    color='#B2EBF2'
                                >
                                    No data
                                </Typography>
                                }
                                
                            </Grid>
                            <Grid item xs={12} sm={8}>

                                {/* TITLE */}
                                <Box
                                    gridColumn="span 12"
                                    backgroundColor='rgba(255, 255, 255, 0.2)'
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Typography
                                        variant="h4"
                                        fontWeight="600"
                                        color='#FFD700'
                                    >
                                        Target saving by BU
                                    </Typography>
                                </Box>
                                
                                {/* CHART */}
                                <Box
                                    gridColumn="span 12"
                                    // backgroundColor={colors.primary[400]}
                                    display="block"
                                    alignItems="center"
                                    justifyContent="center"
                                    sx={{width: '100%'}}
                                >
                                    {
                                    buColumn.length !== 0?
                                    <ColumnChart data={buColumn} height='300' colors="#66C2C2"/> :
                                    <Typography
                                        variant="h4"
                                        fontWeight="600"
                                        color='#B2EBF2'
                                    >
                                        No data
                                    </Typography>
                                    }
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                {/* TABLE */}
                                <Box
                                    // mt='-7%'
                                    gridColumn="span 12"
                                    // backgroundColor={colors.primary[400]}
                                    display="block"
                                    alignItems="center"
                                    justifyContent="center"
                                    sx={{width: '100%'}}
                                >
                                    {
                                    buTable.length !== 0?
                                    <TableContainer component={Paper} sx={{ overflowX: 'auto', width: '100%', backgroundColor: '#1C1B29' }}>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow sx={{ backgroundColor: '#2E3B4E'}}>
                                                    <TableCell sx={{ fontWeight: 'bold', padding: '8px', width: '100px', wordWrap: 'break-word', textAlign: 'center', verticalAlign: 'middle', color: '#B2EBF2' }}>BU</TableCell>
                                                    {buTable.bu.map((item, index) => (
                                                        <TableCell key={index} sx={{ padding: '8px', width: '100px', textAlign: 'center', textAlign: 'center', verticalAlign: 'middle', color: '#B2EBF2' }}>
                                                            {item}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow sx={{ backgroundColor: '#1C1B29' }}>
                                                    <TableCell sx={{ fontWeight: 'bold', padding: '8px', width: '100px', wordWrap: 'break-word', color: '#B2EBF2' }}>No. of Project</TableCell>
                                                    {buTable.project.map((item, index) => (
                                                        <TableCell key={index} sx={{ padding: '8px', width: '100px', textAlign: 'center', wordWrap: 'break-word', color: '#B2EBF2' }}>{item}</TableCell>
                                                    ))}
                                                </TableRow>
                                                <TableRow sx={{ backgroundColor: '#1C1B29' }}>
                                                    <TableCell sx={{ fontWeight: 'bold', padding: '8px', width: '100px', wordWrap: 'break-word', color: '#B2EBF2' }}>Estimated saving</TableCell>
                                                    {buTable.estimated_saving.map((item, index) => (
                                                        <TableCell key={index} sx={{ padding: '8px', width: '100px', textAlign: 'center', wordWrap: 'break-word', color: '#B2EBF2' }}>{item}</TableCell>
                                                    ))}
                                                </TableRow>
                                                <TableRow sx={{ backgroundColor: '#1C1B29' }}>
                                                    <TableCell sx={{ fontWeight: 'bold', padding: '8px', width: '100px', wordWrap: 'break-word', color: '#B2EBF2' }}>Actual saving</TableCell>
                                                    {buTable.actual_saving.map((item, index) => (
                                                        <TableCell key={index} sx={{ padding: '8px', width: '100px', textAlign: 'center', wordWrap: 'break-word', color: '#B2EBF2' }}>{item !== null?parseFloat(item).toFixed(2): 'N/A'}</TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    :
                                    <Typography
                                        variant="h4"
                                        fontWeight="600"
                                        color='#B2EBF2'
                                    >
                                        No data
                                    </Typography>
                                    }
                                </Box>
                            </Grid>
                        </Grid>   
                    </TabPanel>

                    {/* TAB 4 */}
                    <TabPanel value="4" sx={{ minHeight: '90vh', backgroundColor: 'transparent' }}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12}>
                                <Box
                                    mt='1.5%'
                                    display="grid"
                                    gridTemplateColumns="repeat(12, 1fr)"
                                    gridAutoRows="100%"
                                    gap="2%"
                                    >
                                    {/* ESTIMATED */}
                                    <Box
                                        gridColumn="span 3"
                                        backgroundColor='#1C1B29'
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <CardContent
                                            sx={{
                                            backgroundColor: '#2C2B39',
                                            width: '100%',
                                            height: '100%',
                                            borderRadius: '8px',
                                            padding: '18px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                            }}
                                        >
                                            <Typography
                                                variant="h3"
                                                fontWeight="600"
                                                color='#FFD700'
                                            >
                                                Estimated cost saving <br /> {year}
                                            </Typography>
                                            <Typography
                                                variant="h2"
                                                fontWeight="600"
                                                color='#B2EBF2'
                                            >
                                                {teamCostSavingBarChart.length !== 0? teamCostSavingBarChart.target.reduce((a, b) => a + b).toFixed(2)  + ' MB': 'No Data'}
                                            </Typography>
                                        </CardContent>
                                    </Box>
                                    {/* ACTUAL*/}
                                    <Box
                                    gridColumn="span 3"
                                    backgroundColor='#1C1B29'
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    >
                                        <CardContent
                                            sx={{
                                            backgroundColor: '#2C2B39',
                                            width: '100%',
                                            height: '100%',
                                            borderRadius: '8px',
                                            padding: '18px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                            }}
                                        >
                                            <Typography
                                                variant="h3"
                                                fontWeight="600"
                                                color='#FFD700'
                                            >
                                                Actual cost saving <br /> {year}
                                            </Typography>
                                            <Typography
                                                variant="h2"
                                                fontWeight="600"
                                                color='#B2EBF2'
                                            >
                                                {costSavingsLineChart.length !== 0? costSavingsLineChart.actual.reduce((a, b) => a + b).toFixed(2) + ' MB': 'No Data'}
                                            </Typography>
                                        </CardContent>
                                    </Box>
                                    {/* %ACHIEVE */}
                                    <Box
                                    gridColumn="span 3"
                                    backgroundColor='#1C1B29'
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    >
                                        <CardContent
                                            sx={{
                                            backgroundColor: '#2C2B39',
                                            width: '100%',
                                            height: '100%',
                                            borderRadius: '8px',
                                            padding: '18px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                            }}
                                        >
                                            <Typography
                                                variant="h3"
                                                fontWeight="600"
                                                color='#FFD700'
                                            >
                                                % Achieve <br /> {year}
                                            </Typography>
                                            <Typography
                                                variant="h2"
                                                fontWeight="600"
                                                color='#B2EBF2'
                                            >
                                                {teamCostSavingBarChart.length !== 0? (100 * teamCostSavingBarChart.actual.reduce((a, b)=> a+b) / teamCostSavingBarChart.target.reduce((a, b) => a + b)).toFixed(2) + ' %': 'No Data'}
                                            </Typography>
                                        </CardContent>
                                    </Box>
                                    {/* NEXT YEAR */}
                                    <Box
                                        gridColumn="span 3"
                                        backgroundColor='#1C1B29'
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <CardContent
                                            sx={{
                                            backgroundColor: '#2C2B39',
                                            width: '100%',
                                            height: '100%',
                                            borderRadius: '8px',
                                            padding: '18px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                            }}
                                        >
                                            <Typography
                                                variant="h3"
                                                fontWeight="600"
                                                color='#FFD700'
                                            >
                                                Target <br /> {year + 1}
                                            </Typography>
                                            <Typography
                                                variant="h2"
                                                fontWeight="600"
                                                color='#B2EBF2'
                                            >
                                                {costSavingsLineChart.length !== 0? parseFloat(costSavingsLineChart.saving_next_year).toFixed(2) + ' MB': 'No Data'}
                                            </Typography>
                                        </CardContent>
                                    </Box> 
                                </Box>                         
                            </Grid>
                            <Grid item xs={12} sm={12}>

                                {/* TITLE */}
                                <Box
                                    gridColumn="span 12"
                                    backgroundColor='transparent'
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <CardContent
                                            sx={{
                                            backgroundColor: 'transparent',
                                            width: '100%',
                                            height: '100%',
                                            borderRadius: '8px',
                                            padding: '18px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                            }}
                                        >
                                        <Typography
                                            variant="h4"
                                            fontWeight="600"
                                            color='#FFD700'
                                        >
                                            Cost saving {year}
                                        </Typography>
                                    </CardContent>
                                </Box>                              
                                {/* CHART */}
                                <Box
                                    gridColumn="span 12"
                                    // backgroundColor={colors.primary[400]}
                                    display="block"
                                    alignItems="center"
                                    justifyContent="center"
                                    sx={{width: '100%'}}
                                >
                                    {
                                        costSavingsLineChart.length !== 0?<MultiLineGraph data={costSavingsLineChart}/>: 'No Data'
                                    }
                                </Box>
                            </Grid>
                            {/* <Grid item xs={12} sm={12}>
                                <Box
                                mt='-3%'
                                gridColumn="span 12"
                                // backgroundColor={colors.primary[400]}
                                display="block"
                                alignItems="center"
                                justifyContent="center"
                                sx={{width: '100%'}}
                                >
                                    {
                                        teamCostSavingBarChart.length !== 0? <BarChart data={teamCostSavingBarChart}/>: 'No Data'
                                    }  
                                </Box>
                            </Grid> */}
                        </Grid>   
                    </TabPanel>
                </TabContext>
            </Box>
        </Item>
      
    </Box>
  )
}

export default Report
