// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./../assets/fonts/CPF_Imm_Sook_Font/CPF_Imm_Sook_Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./../assets/fonts/CPF_Imm_Sook_Font/CPF_Imm_Sook_SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'CPF Imm Sook';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'CPF Imm Sook';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');
    font-weight: bold;
    font-style: normal;
  }`, "",{"version":3,"sources":["webpack://./src/fonts/fonts.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,+DAA2F;IAC3F,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,2BAA2B;IAC3B,+DAA4F;IAC5F,iBAAiB;IACjB,kBAAkB;EACpB","sourcesContent":["@font-face {\n    font-family: 'CPF Imm Sook';\n    src: url('./../assets/fonts/CPF_Imm_Sook_Font/CPF_Imm_Sook_Regular.ttf') format('truetype');\n    font-weight: normal;\n    font-style: normal;\n  }\n  \n  @font-face {\n    font-family: 'CPF Imm Sook';\n    src: url('./../assets/fonts/CPF_Imm_Sook_Font/CPF_Imm_Sook_SemiBold.ttf') format('truetype');\n    font-weight: bold;\n    font-style: normal;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
