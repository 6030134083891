// import { Box, AppBar, Toolbar, Typography } from "@mui/material";
import { useState } from 'react';
import { Link as RouterLink, useLocation,useNavigate  } from 'react-router-dom';
import mainLogo from '../../assets/images/PMS.png'
import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  Container, Grid,
  Button,
  Menu,
  MenuItem,
  Fade,
  Typography,
  Avatar,
  Tooltip
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import InputIcon from '@mui/icons-material/Input';
import Img from '../../assets/images/smiling.png'
import SettingsIcon from '@mui/icons-material/Settings';
import { styled, useTheme } from '@mui/material/styles';
import { tokens } from "../../theme";

const pages = ['Form', 'Report'];


const Topbar = () => {
  const settings = `${localStorage.getItem('settings')}`.split(",") 
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();  
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (e) => {
    setAnchorElNav(null);
    console.log(e.target.innerText)
    switch (e.target.innerText) {
      case 'FORM':
        navigate(`/form`, { replace: true });     
        break;
      case 'REPORT':
        navigate(`/report`, { replace: true });     
        break;
    
      default:
        break;
    }
  };

  // จะทำงานเมื่อuser click menuในdropdownของAvatarชื่อuserที่มุมขวาของnav
  const handleCloseUserMenu = (e) => {
    setAnchorElUser(null);
    // หลังจากคลิ๊กจะredirectไปตามpathที่วางไว้
    switch (e.target.innerHTML) {
      case 'Logout':
        localStorage.clear();
        navigate('/login', { replace: true });         
        break;
      case 'Account':
        navigate('/settings', { replace: true });      
        break;
      case 'Profile':
        navigate(`/user-edit?id=${localStorage.getItem('rb_UserId_pms')}`, { replace: true });     
        break;
      case 'Setting':
        navigate(`/setting`, { replace: true });     
        break;
    
      default:
        break;
    }
  };


  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="sticky" style={{ background: 'transparent', boxShadow: 'none'}}>
        <Toolbar>
            {/* <Box 
              sx={{ flexGrow: 1 }}
            >
              <div style={{ textAlign: 'left' }}>
                  <Typography
                    variant="h6"
                    noWrap
                    component="div"
                    sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
                  >
                  <RouterLink to="/">
                    <img src={mainLogo} width='50' height='50'></img>
                  </RouterLink>
                  </Typography>
              </div>
            </Box> */}
            <Box 
              sx={{ flexGrow: 1 }}
            >
              <div style={{ textAlign: 'right' }}>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    {/* <Avatar alt={localStorage.getItem("rb_email").toUpperCase()} src="/static/images/avatar/2.jpg" /> */}
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem key={setting} accessKey={setting} onClick={e=>handleCloseUserMenu(e)}>
                      <Typography textAlign="center">{setting}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </div>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Topbar;