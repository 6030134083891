// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-wrapper {
    width: 100%;
    height: 2.5rem;
    border: none;
    border-radius: 10px;
    border-color: #B2EBF2;
    padding: 0 15px;
    box-shadow: 0px 0px 8px #ddd;
    background-color: 'transparent';
    display: flex;
    align-items: center;
    color:#B2EBF2;
  }
  
  input {
    background-color: transparent;
    border: none;
    height: 100%;
    width: 100%;
    margin-left: 5px;
    color: #B2EBF2;
    font-size: 18px;
    font-family: "CPF Imm Sook";
  }
  
  input:focus {
    outline: none;
  }
  
  #search-icon {
    color: #B2EBF2;
  }`, "",{"version":3,"sources":["webpack://./src/components/SearchBar.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,cAAc;IACd,YAAY;IACZ,mBAAmB;IACnB,qBAAqB;IACrB,eAAe;IACf,4BAA4B;IAC5B,+BAA+B;IAC/B,aAAa;IACb,mBAAmB;IACnB,aAAa;EACf;;EAEA;IACE,6BAA6B;IAC7B,YAAY;IACZ,YAAY;IACZ,WAAW;IACX,gBAAgB;IAChB,cAAc;IACd,eAAe;IACf,2BAA2B;EAC7B;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,cAAc;EAChB","sourcesContent":[".input-wrapper {\n    width: 100%;\n    height: 2.5rem;\n    border: none;\n    border-radius: 10px;\n    border-color: #B2EBF2;\n    padding: 0 15px;\n    box-shadow: 0px 0px 8px #ddd;\n    background-color: 'transparent';\n    display: flex;\n    align-items: center;\n    color:#B2EBF2;\n  }\n  \n  input {\n    background-color: transparent;\n    border: none;\n    height: 100%;\n    width: 100%;\n    margin-left: 5px;\n    color: #B2EBF2;\n    font-size: 18px;\n    font-family: \"CPF Imm Sook\";\n  }\n  \n  input:focus {\n    outline: none;\n  }\n  \n  #search-icon {\n    color: #B2EBF2;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
