import * as React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography
} from '@mui/material';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode'
import { apiUrl } from '../../constants';

var urls = apiUrl.LOGIN_URL;

const Login = React.memo(() => {
  const navigate = useNavigate();

  // จะทำงานเมื่อuser คลิ๊กปุ่ม Login to post credential to server
  const handleLogin = React.useCallback(
    async (credentials, setSubmitting, setStatus) => {
      // define body when post API
      var data = {
        email: credentials.email,
        password: credentials.password
      };

      var result;
      try {

        // post username & password to login API
        result = await axios.post(urls, data);
        console.log(result.status, ' ', (result.status === 200))
        if (result.status === 200) {
          // decode token to get expiry and store in localstorage
          localStorage.clear();
          let access_user = jwtDecode(result.data.tokens.accessToken);
          let access_exp = access_user.exp;
          let refresh_user = jwtDecode(result.data.tokens.refreshToken);
          let refresh_exp = refresh_user.exp;
          // console.log('is_admin_pms ', result.data.is_superuser)
          localStorage.setItem('rb_ac_token_pms', result.data.tokens.accessToken);
          localStorage.setItem('rb_rf_token_pms', result.data.tokens.refreshToken);
          localStorage.setItem('rb_ac_exp_pms', access_exp);
          localStorage.setItem('rb_rf_exp_pms', refresh_exp);
          localStorage.setItem('rb_user_role_pms', result.data.user.user_role);
          localStorage.setItem('rb_authen_pms', 'True');
          localStorage.setItem('rb_email_pms', result.data.user.email);
          localStorage.setItem('rb_team_pms', result.data.user.team);
          // localStorage.setItem('rb_Admin_pms', false)
          if (result.data.user.user_role === 'superuser'){
            localStorage.setItem('rb_Admin_pms', true)
          } else {
            localStorage.setItem('rb_Admin_pms', false)
          }
          // console.log('login ', `${localStorage.getItem('rb_Admin_pms')}`)
          if (`${localStorage.getItem('rb_Admin_pms')}` === 'false') 
          {
            localStorage.setItem('settings', ['Profile', 'Overview', 'Logout']); 
          } else {
            localStorage.setItem('settings', ['Profile', 'Account', 'Overview', 'Logout']);
          }

          // redirect to dashboard
          navigate('/home', { replace: true });

        } else {
          // console.log('wrong!');
          setSubmitting(false);
          // set status when user input incorrect username or password
          setStatus('Invalid credentials, try again');
        }
      } catch (error) {
        // console.log('wrong!');
        setSubmitting(false);
        // set status when user input incorrect username or password
        setStatus('Invalid credentials, try again');
      }
    }
  );

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email('Must be a valid email')
                .max(255)
                .required('Email is required'),
              password: Yup.string().min(1).required('Password is required')
            })}
            onSubmit={async (values, { setSubmitting, setStatus }) => {
              setSubmitting(true);
              handleLogin(values, setSubmitting, setStatus);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              status
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Typography color="textPrimary" variant="h3">
                    Sign in
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Sign in on the internal CP pms Dashboard
                  </Typography>
                </Box>
                <Typography color="#d32f2f" variant="body4">
                  {status}
                </Typography>

                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={handleSubmit}
                  >
                    Sign in
                  </Button>
                </Box>
                <Typography color="textSecondary" variant="body2">
                  Forget your password?{' '}
                  <Link
                    component={RouterLink}
                    to="/email"
                    variant="body2"
                    underline="hover"
                    style={{color:'#39607a'}}
                  >
                    Reset Password
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
});

export default Login;
