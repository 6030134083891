import React from 'react'
import { Box, Typography } from '@mui/material'
import Chart from 'react-apexcharts';

const StackedBarChartTeamAveragePercent = (props) => {
    const options = {
        chart: {
          type: 'bar',
          stacked: true,
          backgroundColor: 'transparent',
          foreColor: '#B2EBF2' // Default text color
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              total: {
                offsetX: 5,
                enabled: true,
                style: {
                  fontSize: '13px',
                  fontWeight: 900,
                  color: '#B2EBF2',
                  
                },
                formatter: function (value) {
                  return `${value}%`; // Add percentage sign after the value
                }
              }
            }
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 1,
          colors: ['#fff'],
        },
        // title: {
        //   text: 'Stacked Bar Chart',
        // },
        grid: {
          show: true,      // you can either change hear to disable all grids
          xaxis: {
            lines: {
              show: false  //or just here to disable only x axis grids
             }
           },  
          yaxis: {
            lines: { 
              show: false  //or just here to disable only y axis
             }
           },   
        },
        xaxis: {
          categories: props.data.label,
          labels: {
            show: false, // Hide x-axis labels
          },
          axisBorder: {
            show: false // Hide x-axis border
          },
          axisTicks: {
            show: false // Hide x-axis ticks
          }
        },
        yaxis: {
          show: true,
        //   title: {
        //     text: 'Team',
        //   },
        },
        tooltip: {
          theme: 'dark', // Dark theme for tooltip
          style: {
            fontSize: '14px',
            fontFamily: 'CPF Imm Sook',
            color: '#FFFFFF' // Tooltip text color
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
          offsetX: 40,
        },
        colors: props.colors
      };
    
      const series = props.data.series
    
  return (
    <Box sx={{ width: '100%', display: 'block', justifyContent: 'center' }}>
      <Chart options={options} series={series} type="bar" height={350} width='100%' />
    </Box>
  )
}

export default StackedBarChartTeamAveragePercent
