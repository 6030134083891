// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.results-list {
    width: 100%;
    background-color: black;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 8px #ddd;
    border-radius: 10px;
    margin-top: 1rem;
    max-height: 300px;
    overflow-y: auto;
  }`, "",{"version":3,"sources":["webpack://./src/components/SearchResultList.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,uBAAuB;IACvB,aAAa;IACb,sBAAsB;IACtB,4BAA4B;IAC5B,mBAAmB;IACnB,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;EAClB","sourcesContent":[".results-list {\n    width: 100%;\n    background-color: black;\n    display: flex;\n    flex-direction: column;\n    box-shadow: 0px 0px 8px #ddd;\n    border-radius: 10px;\n    margin-top: 1rem;\n    max-height: 300px;\n    overflow-y: auto;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
